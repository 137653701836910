import { createSlice } from "@reduxjs/toolkit";
import { getClientById, getClientTask } from "../thunks/client";

const initState = {
  loading: false,
  clientUserData: {},
  clientTasksData: [],
  taskLoading: false,
  leadClientData: {},
};

const clientSlice = createSlice({
  name: "clientSlice",
  initialState: initState,
  reducers: {
    updateClientTaskCompleteAndPick: (state, action) => {
      const tempInstructionData = JSON.parse(
        JSON.stringify(state?.clientTasksData)
      );
      const updatedTasksArray = tempInstructionData.map((task) => {
        if (task.id === action?.payload?.id) {
          // If the task has the target ID, update the property
          return {
            ...task,
            status: action?.payload?.data?.status,
            finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment:
              action?.payload?.data
                ?.finalTruthWhatActionCanHeTakeOnTaskLikeGivingComment,
            isCompleted: action?.payload?.data?.isCompleted,
            isDroppedButton: action?.payload?.data?.isDroppedButton,
            isPickedButton: action?.payload?.data?.isPickedButton,
            isCompleteButton: action?.payload?.data?.isCompleteButton,
            modifiedBy: action?.payload?.data?.modifiedBy,
            endDate: action?.payload?.data?.endDate,
          };
        } else {
          // Otherwise, return the original task
          return task;
        }
      });
      state.clientTasksData = updatedTasksArray;
    },
    resetClientUserData: (state) => {
      state.clientUserData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.loading = false;
        state.clientUserData = {
          ...action.payload,
          firstName: action.payload?.firstName ?? "",
          middleName: action.payload?.middleName ?? "",
          lastName: action.payload?.lastName ?? "",
          address: action.payload?.address ?? {},
        };
        state.leadClientData = action.payload;
      })
      .addCase(getClientById.rejected, (state, action) => {
        state.loading = false;
      })

      //Client level tasks list
      .addCase(getClientTask.pending, (state, action) => {
        state.taskLoading = true;
      })
      .addCase(getClientTask.fulfilled, (state, action) => {
        state.taskLoading = false;
        state.clientTasksData = action.payload;
      })
      .addCase(getClientTask.rejected, (state, action) => {
        state.taskLoading = false;
      });
  },
});
export const { updateClientTaskCompleteAndPick, resetClientUserData } =
  clientSlice.actions;
export default clientSlice.reducer;
