import { createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "../helpers/axiosInterceptor";
import { API_ROUTES_PATHS, formNameForPdfGeneration } from "../utils/constant";
import { downloadEmailDocument, viewDocument } from "../utils/util";

//Method to get Instruction Type API
export const getInstructionType = createAsyncThunk(
  "instruction/getInstruction",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(API_ROUTES_PATHS.GET_INSTRUCTION_TYPE);

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

//Method to get all instructions API
export const getAllInstructions = createAsyncThunk(
  "instruction/getAllInstructions",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        API_ROUTES_PATHS.GET_ALL_INSTRUCTIONS,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

//Method to get client care letter API
export const getClientLetter = createAsyncThunk(
  "instruction/getClientLetter",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_BY_ID}/${model}/${API_ROUTES_PATHS.GET_CLIENT_CARE_LETTER}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

//Method to get instruction by ID API
export const getInstructionById = createAsyncThunk(
  "instruction/getInstructionById",
  async ({ id, query }, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_BY_ID}/${id}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to send Mail API
export const sendMailThunk = createAsyncThunk(
  "instruction/sendMailThunk",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.SEND_EMAIL}/${model?.instructionId}/send_mail`,
        model
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get all mail API
export const getMailListThunk = createAsyncThunk(
  "instruction/getMailListThunk",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.GET_EMAILS}`, model);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get Instruction form data by ID API
export const getInstructionFormDataById = createAsyncThunk(
  "instruction/getInstructionFormDataById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_BY_ID}/${model}/instruction`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update instruction form data (QC) API
export const updateInstruction = createAsyncThunk(
  "instruction/updateInstruction",
  async ({ id, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INSTRUCTION}/${id}/instructionedit`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update instruction on dashboard screen API
export const updateInstructionProp = createAsyncThunk(
  "instruction/updateInstructionProp",
  async ({ id, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INSTRUCTION}/${id}/instruction`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update instruction data on will suite API
export const updatewillSuiteThunk = createAsyncThunk(
  "instruction/UpdatewillSuite",
  async ({ id, ...model }, thunkApi) => {
    try {
      let willsuiteCase = model?.willsuiteCase;
      /* If will suite case ID execute If block with put request */
      if (willsuiteCase) {
        const res = await getAxios().put(
          `${API_ROUTES_PATHS.UPDATE_INSTRUCTION}/${id}/${API_ROUTES_PATHS.UPDATE_WILL_SUIT}`,
          model
        );
        const responseData = res.data;
        return responseData;
      } else {
        /* else execute else block with post request */
        const res = await getAxios().post(
          `${API_ROUTES_PATHS.UPDATE_INSTRUCTION}/${id}/${API_ROUTES_PATHS.UPDATE_WILL_SUIT}`,
          model
        );
        const responseData = res.data;
        return responseData;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create notes and document API (On instruction dashboard screen)
export const createNotesAndDocument = createAsyncThunk(
  "instruction/notesAndDocument",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.CREATE_INSTRUCTION_NOTES}/${model?.id}/notes`,
        model?.data
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get notes and documents by ID API (On Instruction dashboard screen)
export const getNotesAndDocumentById = createAsyncThunk(
  "instruction/getNotesAndDocumentById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_NOTES_AND_DOCUMENT_BY_ID}/${model}/notes`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get products on instruction API (Instruction dashboard)
export const getInstructionProducts = createAsyncThunk(
  "instruction/getInstructionProducts",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_PRODUCT_BY_ID}/${model}/allproductsofinstruction`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get generic task API (Instruction Dashboard)
export const getGenericInstructionTasks = createAsyncThunk(
  "instruction/getGenericInstructionTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_GENERIC_TASK_FOR_INSTRUCTION}/${model}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get instruction products tasks by passing instruction ID and productId
export const getInstructionProductsTaskById = createAsyncThunk(
  "instruction/getInstructionProductsTaskById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_PRODUCT_TASK_BY_ID}/${model?.instructionId}/instructiOnProductTasks/${model?.productId}`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get generic task API --> New task dialog  (New Task Create)
export const getGenericTasksForNewTasks = createAsyncThunk(
  "instruction/getGenericTasksForNewTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_GENERIC_TASK_FOR_INSTRUCTION}/${model?.instructionId}`,
        { params: model?.query }
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get instruction products tasks by passing instruction ID and productId for new task create
export const getInstructionProductsTaskByIdForNew = createAsyncThunk(
  "instruction/getInstructionProductsTaskByIdForNew",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_PRODUCT_TASK_BY_ID}/${model?.payload?.instructionId}/instructiOnProductTasks/${model?.payload?.productId}`,
        { params: model?.query }
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get task answer details API (Instruction Dashboard)
export const getInstructionTaskAnswerDetails = createAsyncThunk(
  "instruction/getInstructionTaskAnswerDetails",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_TASK_ANSWER_DETAILS}/${model}/client`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update instruction task answers API
export const updateInstructionTaskAnswer = createAsyncThunk(
  "instruction/updateInstructionTaskAnswer",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INSTRUCTION_TASK_ANSWER}/${model?.id}`,
        model?.tempData
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get instruction task answer history API
export const getInstructionTaskAnswerHistory = createAsyncThunk(
  "instruction/getInstructionTaskAnswerHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_TASK_HISTORY}/${model}/documents`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get custom instruction task answer history API
export const getCustomInstructionTaskAnswerHistory = createAsyncThunk(
  "instruction/getCustomInstructionTaskAnswerHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CUSTOM_INSTRUCTION_TASK_ANSWER_HISTORY}/${model}/documents`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Instruction Documents As Per Document Type
export const getInstructionDocumentsAsPerDocumentType = createAsyncThunk(
  "instruction/getInstructionDocumentsAsPerMilestone",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INSTRUCTION_DOCUMENTS_AS_PER_DOCUMENT_TYPE}/${model?.instructionId}/documentsByDocumentsType`,
        { params: model?.query }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to allocate or complete Task (Instruction Dashboard)
export const allocateOrCompleteTask = createAsyncThunk(
  "instruction/allocateOrCompleteTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.ALLOCATE_OR_COMPLETE_TASK}/${model?.id}/allocateOrCompleteTask`,
        model
      );
      // let res = await getAxios().patch(
      //   `${API_ROUTES_PATHS}/V3/instructiontaskNewest/${model?.id}/allocateOrCompleteTask`,
      //   model
      // );

      // http://localhost:8083/api/V3/instructiontaskNewest/8850/allocateOrCompleteTask
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Get Meeting Logs by passing instruction ID (Instruction Dashboard)
export const getMeetingLogs = createAsyncThunk(
  "instruction/getMeetingLogs",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_MEETING_LOGS}/${model}/meetingLogs`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update commission API (Instruction Dashboard)
export const updateCommission = createAsyncThunk(
  "instruction/updateCommission",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.UPDATE_COMMISSION}/${model?.id}/isinstructioncommissionapplicable`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to create QC check list questions API
export const CreateQCCheckListQuestions = createAsyncThunk(
  "instruction/CreateQCCheckListQuestions",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.CREATE_QC_CHECKLIST_QUESTIONS}/${model}/qcQuestionAnsCreate`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update QC check list question & answer API
export const updateQCCheckList = createAsyncThunk(
  "instruction/updateQCCheckList",
  async ({ id, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_QC_CHECKLIST}/${id}/qcQuestionAns`,
        model?.data
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get QC checkList data API (QC Form)
export const getQCCheckListById = createAsyncThunk(
  "instruction/getQCCheckListById",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_QC_CHECKLIST_BY_ID}/${model}/qcQuestionAns`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get Consultant Introducer Commission List On Intstruction Dashboard
export const getIntroducerConsultantCommission = createAsyncThunk(
  "instruction/getIntroducerConsultantCommission",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CONSULTANT_INTRODUCER_COMMISSION_LIST}/${model}/instructionCommissions`
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to update consultant introducer commission Invoice statusAPI
export const updateConsultantIntroducerCommissionStatus = createAsyncThunk(
  "instruction/updateConsultantIntroducerCommissionStatus",
  async ({ id, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INTRODUCTER_CONSULTANT_COMMISSION_STATUS}/${id}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to transfer Instruction to consultant API
export const transferInstructionToConsultant = createAsyncThunk(
  "instruction/transferInstructionToConsultant",
  async ({ instructionId, ...model }, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.TRANSFER_INSTRUCTION}/${instructionId}/instructiontransfer`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to get users for assign task --> New task dialog  (New Task Create)
// get only office admin and instruction owner list
export const getUserForAssignTask = createAsyncThunk(
  "instruction/getUserForAssignTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_USER_FOR_ASSIGN_TASK}/${model}/officeAdminsAndOwner`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Create Duplicate Task API
export const createNewTask = createAsyncThunk(
  "instruction/createNewTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().post(
        `${API_ROUTES_PATHS.CREATE_NEW_TASK}`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* GEt Duplicate Task for instructions*/
export const getIndividualTasks = createAsyncThunk(
  "instruction/getIndividualTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INDIVIDUAL_TASKS}/${model}/instructionAllIndividualTasks`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* GEt Custom task for instructions*/
export const getCustomTasks = createAsyncThunk(
  "instruction/getCustomTasks",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_CUSTOM_TASKS}/${model}/instructionAllCustomTasks`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Method to update instruction task answers API */
export const updateIndividualInstructionTaskAnswer = createAsyncThunk(
  "instruction/updateIndividualInstructionTaskAnswer",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INDIVIDUAL_INSTRUCTION_TASK_ANSWER}/${model?.id}/individualInstructionTasks`,
        model?.tempData
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to allocate or complete Task for Duplicate Task (Instruction Dashboard)
export const allocateOrCompleteIndividualTask = createAsyncThunk(
  "instruction/allocateOrCompleteIndividualTask",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.ALLOCATE_OR_COMPLETE_INDIVIDUAL_TASK}/${model?.id}/changeStatusOfIndividualTask`,
        model
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Get is instruction is valid to raise bill or not*/
export const getIsInstructionValidToRaiseBill = createAsyncThunk(
  "instruction/getIsInstructionValidToRaiseBill",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.IS_VALID_INSTRUCTION_TO_RAISE_BILL}/${model}/isInstructionValidToBillRaise`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Get is instruction is valid to raise bill or not*/
export const readImage = createAsyncThunk(
  "instruction/readImage",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`${API_ROUTES_PATHS.READ_IMAGE}`, {
        params: model?.query,
      });
      const responseData = res.data;
      if (responseData) {
        viewDocument(responseData);
      }
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update Due Date (Instruction Dashboard)
export const updateDueDate = createAsyncThunk(
  "instruction/updateDueDate",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_DUE_DATE}/${model?.id}/pushDueDate`,
        { dueDate: model?.dueDate, note: model?.note }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// Method to Update Individual Due Date (Instruction Dashboard)
export const updateIndividualDueDate = createAsyncThunk(
  "instruction/updateIndividualDueDate",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().patch(
        `${API_ROUTES_PATHS.UPDATE_INDIVIDUAL_DUE_DATE}/${model?.id}/pushDueDatesOfInstructionIndividualTask`,
        { dueDate: model?.dueDate, note: model?.note }
      );

      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Get due date history in due date dialog*/
export const getDueDateHistory = createAsyncThunk(
  "instruction/getDueDateHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_DUE_DATE_HISTORY}/${model}/dueDateHistory`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/* Get individual task due date history in due date dialog*/
export const getIndividualDueDateHistory = createAsyncThunk(
  "instruction/getIndividualDueDateHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(
        `${API_ROUTES_PATHS.GET_INDIVIDUAL_DUE_DATE_HISTORY}/${model}/historyOfDueDatePushed`
      );
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// method to download form PDF
export const downloadFormPdf = createAsyncThunk(
  "instruction/downloadFormPdf",
  async (model, thunkApi) => {
    try {
      let getUrl = `${API_ROUTES_PATHS.INSTRUCTION_ROUTE}/${
        model?.instructionId
      }/${formNameForPdfGeneration[model?.formNumber]}${
        API_ROUTES_PATHS.MAKE_PDF_ROUTE
      }`;
      let res = await getAxios().get(getUrl);
      await downloadEmailDocument(res.data);
      return true;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

// method to download addressbook document PDF
export const downloadAddressBookDocumentPdf = createAsyncThunk(
  "instruction/downloadAddressBookDocumentPdf",
  async (model, thunkApi) => {
    try {
      let {
        instructionId,
        personId,
        documentType,
        documentId,
        signatureId,
        filename,
      } = model;
      let getUrl = `${API_ROUTES_PATHS.INSTRUCTION_ROUTE}/${instructionId}/${API_ROUTES_PATHS.ADDRESS_BOOK_DOCUMENT_PDF}${API_ROUTES_PATHS.MAKE_PDF_ROUTE}?personId=${personId}&${API_ROUTES_PATHS.CERTIFICATE_TYPE}=${documentType}&${API_ROUTES_PATHS.CERTIFICATE_ID}=${documentId}&${API_ROUTES_PATHS.SIGNATURE_ID}=${signatureId}`;

      let res = await getAxios().get(getUrl);
      await downloadEmailDocument(res.data, filename);
      return true;
    } catch (error) {
      // console.log("downloadAddressBookDocumentPdf error :: ", error?.response?.data);
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const getMilestoneHistory = createAsyncThunk(
  "instruction/getMilestoneHistory",
  async (model, thunkApi) => {
    try {
      let res = await getAxios().get(`instruction/${model}/milestonehistory`);
      const responseData = res.data;
      return responseData;
    } catch (error) {
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);
