import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { updateInstructionDashboardData } from "../../../slice/instruction";
import { showSnackbar } from "../../../slice/snackbarSlice";
import {
  updateCommission,
  updateInstructionProp,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";
import AlertComponent from "../../../components/alert";
import { getInstructionById } from "../../../thunks/instruction";

const UpdateInstructionStatusDialog = ({
  handleClose,
  open,
  data,
  statusKey,
  isIntroducersCommissionApplicable,
  isIntroducerCommissionShowOrNot,
  statusId,
}) => {
  const dispatch = useDispatch();
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  const userDetails = useSelector((state) => state?.auth?.currentUser);

  // Set initial value of selectedStatusId to statusId
  const [selectedStatusId, setSelectedStatusId] = useState(statusId);
  const [isLoader, setLoader] = useState(false);
  const [isUpdateInstructionStatusDialog, setUpdateInstructionStatusDialog] =
    useState(false);

  const handleChangeFormData = (e) => {
    setSelectedStatusId(e.target.value);
  };

  // Method to open update instruction status dialog
  const handleOpenUpdateInstructionStatusDialog = () => {
    setUpdateInstructionStatusDialog(true);
  };

  /* Update method to handle commission, instruction status, XERO invoice status */
  const handleUpdate = async () => {
    setUpdateInstructionStatusDialog(false);
    if (statusKey === "isCommission") {
      await handleUpdateCommission();

      await dispatch(
        getInstructionById({
          id: instructionData?.id,
        })
      );
    } else {
      try {
        let tempKey = null;
        if (statusKey === "instructionStatus") {
          tempKey = "instructionStatusLookupId";
        } else if (statusKey === "invoice") {
          tempKey = "invoiceStatusLookupId";
        }
        setLoader(true);
        let updatedInstruction = await dispatch(
          updateInstructionProp({
            id: instructionData?.id,
            [tempKey]: parseInt(selectedStatusId),
          })
        ).unwrap();
        if (updatedInstruction) {
          dispatch(
            updateInstructionDashboardData({
              data: updatedInstruction,
              key: statusKey,
            })
          );
          setLoader(false);
          dispatch(
            getInstructionById({
              id: instructionData?.id,
            })
          );
          handleClose();

          dispatch(
            showSnackbar({
              message: NOTIFICATION_MESSAGE?.INSTRUCTION_UPDATE_SUCCESS,
              severity: "success",
            })
          );
        }
      } catch (error) {
        setLoader(false);
        setUpdateInstructionStatusDialog(false);
        handleClose();
        dispatch(
          showSnackbar({
            message: error?.message,
            severity: "error",
          })
        );
      }
    }
  };

  /* Method to update commission (Squiggle Marketing Fee (SMF) Applicable) */
  const handleUpdateCommission = async () => {
    setLoader(true);
    try {
      const payload = {
        id: instructionData?.id,
        status: isIntroducersCommissionApplicable ? false : true,
      };
      const response = await dispatch(updateCommission(payload)).unwrap();
      if (response) {
        setLoader(false);
        handleClose();
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.INSTRUCTION_UPDATE_SUCCESS,
            severity: "success",
          })
        );

        dispatch(
          updateInstructionDashboardData({
            data: true,
            key: statusKey,
          })
        );
      } else {
        handleClose();
        setLoader(false);
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.SOMETHING_WENT_WRONG,
            severity: "error",
          })
        );
      }
    } catch (error) {
      handleClose();
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      {/* confirmation dialog to update status */}
      {isUpdateInstructionStatusDialog && (
        <AlertComponent
          open={isUpdateInstructionStatusDialog}
          handleSuccess={handleUpdate}
          handleClose={() => setUpdateInstructionStatusDialog(false)}
          message={"Are you sure, you want to update the status?"}
        />
      )}

      <Dialog
        sx={{ border: 1 }}
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
      >
        {/* Loader Component */}
        {isLoader && <Loader />}
        <DialogTitle>
          {" "}
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            Change Status
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers={true}>
          <Grid container spacing={3}>
            {/* {statusKey === "isCommission" &&
              isIntroducersCommissionApplicable && (
                <Grid item xs={12}>
                  <FormLabel style={{ color: "#d32f2f" }}>
                    Squiggle Marketing Fee (SMF) already provided.
                  </FormLabel>
                </Grid>
              )} */}

            {/* {statusKey === "isCommission" &&
              !isIntroducerCommissionShowOrNot &&
              !isIntroducersCommissionApplicable && (
                <Grid item xs={12}>
                  <FormLabel style={{ color: "#d32f2f" }}>
                    Squiggle marketing fees (SMF) not applicable.
                  </FormLabel>
                </Grid>
              )} */}
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="status-radio-buttons-group"
                  value={selectedStatusId}
                  name="radio-buttons-group"
                  onChange={handleChangeFormData}
                >
                  {data?.length > 0 &&
                    data.map((d) => (
                      <FormControlLabel
                        key={d.id}
                        style={{
                          display:
                            userDetails?.role === USER_ROLE.CONSULTANT &&
                            d?.name !== "Closed - Pitch n Miss"
                              ? "none"
                              : "block",
                        }}
                        value={statusKey === "isCommission" ? d?.value : d?.id}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#00CE3F",
                                display:
                                  d?.value ===
                                    isIntroducersCommissionApplicable && "none",
                              },
                            }}
                          />
                        }
                        label={
                          d?.value !== isIntroducersCommissionApplicable &&
                          d?.name
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                // disabled={
                //   (statusKey === "isCommission" &&
                //     isIntroducersCommissionApplicable) ||
                //   (statusKey === "isCommission" &&
                //     !isIntroducerCommissionShowOrNot &&
                //     !isIntroducersCommissionApplicable)
                // }
                style={{
                  backgroundColor: "#00ce3f",

                  borderRadius: "20px",
                  padding: "5px 40px ",
                  color: "white",
                }}
                onClick={handleOpenUpdateInstructionStatusDialog}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateInstructionStatusDialog;
