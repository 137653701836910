import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  AccordionDetails,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ContainedGreenButton } from "../../../components/buttons";
import { USER_ROLE } from "../../../utils/constant";
import {
  downloadDocument,
  downloadEmailDocument,
  getModifiedFileName,
} from "../../../utils/util";
import TransferEmailDialog from "./TransferEmailDialog";
import EmailWithStatus from "./EmailWithStatus";

const InstructionEmails = ({ list, clientData }) => {
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [activeEmail, setActiveEmail] = useState(null);
  const [isEmailTransfer, setIsEmailTransfer] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const handleClickEmailTransper = (data) => {
    setSelectedEmail(data);
    setIsEmailTransfer(true);
  };
  return (
    <>
      {isEmailTransfer && (
        <TransferEmailDialog
          open={isEmailTransfer}
          handleClose={() => setIsEmailTransfer(false)}
          selectedEmail={selectedEmail}
        />
      )}
      <Grid
        className="instruction_dashboard_scrollbar"
        style={{
          overflow: "auto",
          height: "50vh",
          paddingTop: "1rem",
        }}
      >
        {/* Dialogue to show full mail */}
        <Dialog
          open={Boolean(activeEmail)}
          onClose={() => setActiveEmail(null)}
          maxWidth={"md"}
          fullWidth
          scroll="paper"
          id="instruction-email"
        >
          <DialogTitle
            style={{ textAlign: "center" }}
            className="serif_display_regular_26 green"
          >
            Email
          </DialogTitle>
          <DialogContent p={2}>
            <Grid item xs={12} direction={"column"} container rowSpacing={2}>
              <Grid item>
                <Typography className="normal_normal_600_14_Manrope green">
                  From
                </Typography>
                <Typography className="normal_normal_600_14_Manrope dark_grey">
                  {activeEmail?.from}
                </Typography>

                <Typography className="normal_normal_600_14_Manrope">
                  {moment(new Date(activeEmail?.createdAt))?.format(
                    "DD/MM/YY hh:mm A"
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="normal_normal_600_14_Manrope green">
                  To
                </Typography>
                <Typography className="normal_normal_600_14_Manrope">
                  {activeEmail?.to?.join(", ")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="normal_normal_600_14_Manrope green">
                  Subject
                </Typography>
                <Typography
                  className=" normal_normal_600_14_Manrope dark_grey"
                  component={"span"}
                >
                  {activeEmail?.subject}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="normal_normal_600_14_Manrope green">
                  Message
                </Typography>
                <Box dangerouslySetInnerHTML={{ __html: activeEmail?.html }} />
              </Grid>
              <Grid item>
                <Typography className="normal_normal_600_14_Manrope green">
                  Attachments
                </Typography>

                {activeEmail?.attachments?.length > 0 && (
                  <>
                    {activeEmail?.attachments?.map((file, j) => {
                      return (
                        <Chip
                          style={{ marginRight: "10px" }}
                          key={`attachment${j}`}
                          label="Attachment"
                          onClick={() => downloadEmailDocument(file)}
                        />
                      );
                    })}
                  </>
                )}

                {activeEmail?.InstructionTaskDocuments?.length ? (
                  <Box paddingBottom={1}>
                    <hr />
                    <Box display={"flex"} columnGap={1} pt={1} gap={1}>
                      {activeEmail?.InstructionTaskDocuments?.map((file, j) => {
                        return (
                          <Chip
                            key={`attachment${j}`}
                            label={
                              file?.fileInfo?.modifiedFileName ||
                              getModifiedFileName(file?.documentUrl) ||
                              "NA"
                            }
                            onClick={() =>
                              downloadDocument(file, clientData, "")
                            }
                          />
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2, borderTop: "1px solid" }}>
            <ContainedGreenButton
              onClick={() => setActiveEmail(null)}
              label={"OK"}
            />
          </DialogActions>
        </Dialog>
        {/* List of mails on case dashboard page */}
        {list?.length > 0 ? (
          list?.map((data, i) => {
            let isPinned = Boolean(
              data?.leadClientId && data?.leadClientId !== null
            );
            return (
              <AccordionDetails key={"email" + i}>
                <div>
                  <Grid item sx={{ paddingTop: "0 !important", mb: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography className="normal_normal_600_14_Manrope green">
                        From{" "}
                      </Typography>
                      {isPinned ? (
                        <Tooltip title="This is a pinned email of client">
                          <PushPinIcon
                            size="small"
                            color="action"
                            sx={{ color: "#00ce3f", fontSize: "18px" }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        className="normal_normal_600_14_Manrope dark_grey"
                        style={{
                          flexGrow: 1,
                          marginRight: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // whiteSpace: "normal", // Allows the text to wrap to the next line
                          wordWrap: "break-word", // Breaks long words if necessary
                        }}
                      >
                        {data?.from}
                      </Typography>
                    </div>

                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="normal_normal_600_14_Manrope">
                        {data?.createdAt
                          ? moment(new Date(data?.createdAt))?.format(
                              "DD/MM/YY hh:mm A"
                            )
                          : "NA"}
                      </Typography>
                      {/* Transfer email section only for super admin */}
                      {userDetails?.role === USER_ROLE.SUPER_ADMIN && (
                        <Tooltip title="Transfer Email">
                          <IconButton
                            onClick={() => handleClickEmailTransper(data)}
                          >
                            <TransferWithinAStationIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography className="normal_normal_600_14_Manrope green">
                      To
                    </Typography>
                    {data?.toDeliverability?.map((d) => (
                      <EmailWithStatus d={d} />
                    ))}
                  </Grid>
                  {data?.ccDeliverability?.length ? (
                    <Grid item sx={{ mb: 2 }}>
                      <Typography className="normal_normal_600_14_Manrope green">
                        CC
                      </Typography>
                      {data?.ccDeliverability?.map((d) => (
                        <EmailWithStatus d={d} />
                      ))}
                    </Grid>
                  ) : null}
                  {data?.bccDeliverability?.length ? (
                    <Grid item sx={{ mb: 2 }}>
                      <Typography className="normal_normal_600_14_Manrope green">
                        BCC
                      </Typography>
                      {data?.bccDeliverability?.map((d) => (
                        <EmailWithStatus d={d} />
                      ))}
                    </Grid>
                  ) : null}

                  <Grid item sx={{ mb: 2 }}>
                    <Typography className="normal_normal_600_14_Manrope green">
                      Subject
                    </Typography>
                    <Typography
                      className=" normal_normal_600_14_Manrope dark_grey"
                      component={"span"}
                    >
                      {data?.subject}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      onClick={() => setActiveEmail(data)}
                      className="normal_normal_600_14_Manrope green"
                    >
                      READ FULL EMAIL
                    </Button>
                  </Grid>
                </div>
                <Divider
                  style={{ marginTop: "1rem", border: "0.5px solid lightgrey" }}
                />
              </AccordionDetails>
            );
          })
        ) : (
          <Typography textAlign={"center"}>No Data</Typography>
        )}
      </Grid>
    </>
  );
};

export default InstructionEmails;
