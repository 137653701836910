/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/Squiggle_Logo_White.png";
import DefaultImage from "../../../assets/images/Squiggle_S_Square_Green.png";

import { Divider, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useThemeContext } from "../../../contexts/theme";
import {
  IMG_BASE_URL,
  USER_ROLE,
  sideBarWithIconAndName,
} from "../../../utils/constant";
import {
  hideShowCommissionMenu,
  includesStringFromArray,
} from "../../../utils/util";
import { Manage_Submenu } from "./adminMenu";

const SideBar = forwardRef((props, ref) => {
  const themeObj = useThemeContext();
  const navigate = useNavigate();
  const Location = useLocation();
  const user = useSelector((state) => state.auth.currentUser);
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const [t, I18n] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [activeItemKey, setActiveItemKey] = useState(null);
  const [openMenu, setOpenMenu] = useState({
    withLabel: false,
    withoutLabel: false,
  });
  const getThemedTextColor = () => {
    if (themeObj.theme === "light") {
      return "text-dark";
    } else {
      return "text-white";
    }
  };
  const getThemedBgColor = () => {
    if (themeObj.theme === "light") {
      return "bg-light";
    } else {
      return "bg-light";
    }
  };

  const onClickMenuItem = (key) => {
    if (window.screen.width && window.screen.width < 600) {
      toggleSidebar();
    }
    setActiveItemKey(key);
  };

  const toggleSidebar = () => {
    if (ref.current.classList.contains("sb-active")) {
      ref.current.classList.remove("sb-active");
    } else {
      ref.current.classList.add("sb-active");
    }
  };

  const handleClickOpenProfileOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfileOptions = () => {
    setAnchorEl(null);
  };

  const handleNavigateToProfilePage = () => {
    navigate("/app/profile");
    setAnchorEl(null);
    // TO HIDE SELECTED MENU
    setActiveItemKey(null);
  };

  const handleNavigateToTechSupportPage = () => {
    navigate("/app/tech-support");
    setAnchorEl(null);
    // TO HIDE SELECTED MENU
    setActiveItemKey(null);
  };

  const handleNavigateToCommissionPage = () => {
    navigate("/app/commissions/list");
    setAnchorEl(null);
    // TO HIDE SELECTED MENU
    setActiveItemKey(null);
  };
  const handleClickMenuWithlabel = (path) => {
    navigate(path);
    setOpenMenu({ withLabel: false, withoutLabel: false });
  };

  //TO SHOW THE CSS PROPERTY OF SELECTED MENU
  const handleClick = (key) => {
    setActiveItemKey(key);
  };

  const handleLogout = () => {
    // googleLogout();
    Cookies.remove("jwt-token");
    navigate("/login");
  };
  // TO OPEN MENU ON SIDEBAR WITH ICON ONLY
  const openMenuwithoutLabel = (item) => {
    if (item?.isManage) {
      setOpenMenu({
        ...openMenu,
        withoutLabel: true,
      });
    }
  };
  // TO OPEN MENU ON SIDEBAR WITH ICON + LABEL
  const openMenuwithLabel = (item) => {
    if (item?.isManage) {
      setOpenMenu({
        ...openMenu,
        withLabel: true,
      });
    }
  };

  //TO SHOW DASHBOARD SELECTED AFTER LOGIN OR ON LAUNCH APPLICATION
  useEffect(() => {
    if (Location.pathname === "/app/dashboard") {
      setActiveItemKey("routekey-home");
    }
  }, [Location.pathname === "/app/dashboard"]);
  return (
    <>
      {!Location.pathname.includes("/forms") &&
      includesStringFromArray(Location.pathname, sideBarWithIconAndName) ? (
        <>
          {/* Sidebar with Icon */}
          <div className="d-flex align-content-start  h-100">
            <nav
              ref={ref}
              className={`h-100 sidebar_instruction_dashboard ${getThemedTextColor()} ${getThemedBgColor()}`}
            >
              <a
                href="/"
                className={`d-flex  mb-md-0 me-md-auto ${getThemedTextColor()} text-decoration-none`}
              >
                <span className={`fs-4 px-1 py-1 mb-4 mt-1`}>
                  <img
                    src={DefaultImage}
                    alt="website_logo"
                    style={{ height: "60px" }}
                  />
                </span>
              </a>

              <ul className="nav flex-column mb-auto">
                {props?.sidebarMenu?.map((item, index) => {
                  if (!item.subMenu) {
                    return (
                      <>
                        <li
                          key={item.key}
                          className="nav-item m-2"
                          onClick={() => onClickMenuItem(item?.key)}
                          style={{
                            height: `${
                              item?.path === Location?.pathname
                                ? "64px"
                                : "64px"
                            }`,
                            width: `${
                              item?.path === Location?.pathname &&
                              item?.key === activeItemKey
                                ? "215px"
                                : ""
                            }`,
                            backgroundColor: `${
                              item?.path === Location?.pathname &&
                              item?.key === activeItemKey
                                ? "#00ce3f"
                                : ""
                            }`,
                            borderTopRightRadius: `${
                              item?.path === Location?.pathname &&
                              item?.key === activeItemKey
                                ? "50px"
                                : ""
                            }`,
                            borderBottomRightRadius: `${
                              item?.path === Location?.pathname &&
                              item?.key === activeItemKey
                                ? "50px"
                                : ""
                            }`,
                            fontWeight: `${
                              item?.path === Location?.pathname ? "bold" : ""
                            }`,
                            zIndex: `${
                              item?.path === Location?.pathname ? 1 : ""
                            }`,
                            display: hideShowCommissionMenu(item, userDetails),
                          }}
                        >
                          <NavLink
                            to={item.path}
                            className={`nav-link text-start ${getThemedTextColor()} ${
                              item.vissbleOnSmallScrren
                                ? ""
                                : "d-none d-md-block"
                            }`}
                            aria-current="page"
                          >
                            <span
                              className="sidebar_Icons"
                              style={{
                                color: "#FFF",
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                              }}
                              onClick={() => openMenuwithoutLabel(item)}
                            >
                              {item?.isManage ? (
                                item?.icon
                              ) : (
                                <img src={item.icon} alt="squiggle" />
                              )}
                            </span>
                          </NavLink>
                        </li>
                        {/* MENU SECTION FOR SIDEBAR WITH ICON ONLY */}
                        <Menu
                          slotProps={{
                            paper: {
                              sx: {
                                borderRadius: "10px",
                                width: "150px",
                              },
                            },
                          }}
                          open={openMenu.withoutLabel}
                          onClose={() =>
                            setOpenMenu({ ...openMenu, withoutLabel: false })
                          }
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          style={{
                            marginLeft: "4.5rem",
                            marginTop: "16rem",
                          }}
                          sx={{
                            "& .MuiList-root": {
                              background: "#3D4740",
                              opacity: 0.9,
                              color: "white",
                            },
                            "& .MuiMenuItem-root": {
                              gap: 2,
                            },
                          }}
                        >
                          {Manage_Submenu?.map((item) => (
                            <>
                              <MenuItem
                                key={item?.key}
                                onClick={() =>
                                  handleClickMenuWithlabel(item?.path)
                                }
                              >
                                {item?.icon} {item?.label}
                              </MenuItem>
                              <Divider
                                key={item?.key}
                                style={{ border: "1px solid white" }}
                              />
                            </>
                          ))}
                        </Menu>
                      </>
                    );
                  }
                })}
              </ul>

              <ul
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "70px",
                  backgroundColor: "#3D4740",
                  height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                className="nav flex-column mb-auto"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickOpenProfileOptions}
              >
                <li className="nav-item">
                  <NavLink className="nav-link text-center" aria-current="page">
                    <div
                      style={{
                        color: "#FFF",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          border: `2px solid #00CE3F`,
                        }}
                        src={
                          user?.profilePhotoUrl &&
                          user?.profilePhotoUrl?.startsWith("https")
                            ? user?.profilePhotoUrl
                            : user?.profilePhotoUrl === null
                            ? DefaultImage
                            : `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${user?.profilePhotoUrl}`
                        }
                      />
                    </div>
                  </NavLink>
                </li>
              </ul>

              <Menu
                slotProps={{
                  paper: {
                    sx: { borderRadius: "24px" },
                  },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseProfileOptions}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{ marginLeft: "0.5rem" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiList-root": {
                    opacity: 0.9,
                    color: "white",
                    backgroundColor: "#3D4740",
                  },
                }}
              >
                <MenuItem onClick={handleNavigateToProfilePage}>
                  My Account
                </MenuItem>

                {userDetails?.role !== USER_ROLE?.INTRODUCER ? (
                  <MenuItem onClick={handleNavigateToTechSupportPage}>
                    Tech Support
                  </MenuItem>
                ) : null}

                {userDetails?.role === USER_ROLE?.CONSULTANT && (
                  <MenuItem onClick={handleNavigateToCommissionPage}>
                    My Commissions
                  </MenuItem>
                )}

                <MenuItem className="green" onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            </nav>
          </div>
        </>
      ) : !Location.pathname.includes("/forms/") &&
        !includesStringFromArray(Location.pathname, sideBarWithIconAndName) ? (
        /* Sidebar with Icon + Name */
        <>
          <div className="d-flex align-content-start sidebar_scroll">
            <nav
              ref={ref}
              className={`sidebar ${getThemedTextColor()} ${getThemedBgColor()}`}
              style={{
                height:
                  Location.pathname.includes("/app/manage/*") ||
                  Location.pathname.includes("/app")
                    ? `${window.innerHeight + 160}px`
                    : "",
              }}
            >
              <a
                href="/"
                className={`d-flex  mb-md-0 me-md-auto ${getThemedTextColor()} text-decoration-none`}
              >
                <span className={`fs-4 px-3 py-1 mb-4 mt-3`}>
                  <img
                    src={Logo}
                    alt="website_logo"
                    style={{ height: "60px" }}
                  />
                </span>
              </a>

              <ul className="nav flex-column mb-auto">
                {props?.sidebarMenu?.map((item, index) => {
                  if (!item.subMenu) {
                    return (
                      <li
                        key={item.key}
                        onClick={() => handleClick(item?.key)}
                        className="nav-item m-2"
                        style={{
                          width: `${
                            (item?.path === Location?.pathname || "#") &&
                            item?.key === activeItemKey
                              ? "213px"
                              : ""
                          }`,
                          backgroundColor: `${
                            (item?.path === Location?.pathname || "#") &&
                            item?.key === activeItemKey
                              ? "#00ce3f"
                              : ""
                          }`,
                          borderTopRightRadius: `${
                            (item?.path === Location?.pathname || "#") &&
                            item?.key === activeItemKey
                              ? "50px"
                              : ""
                          }`,
                          borderBottomRightRadius: `${
                            (item?.path === Location?.pathname || "#") &&
                            item?.key === activeItemKey
                              ? "50px"
                              : ""
                          }`,
                          fontFamily: `${
                            item?.path === Location?.pathname
                              ? "Manrope-Bold"
                              : "Manrope-Regular"
                          }`,
                          fontSize: `${
                            item?.path === Location?.pathname ? "18px" : "18px"
                          }`,
                          zIndex: `${
                            item?.path === Location?.pathname ? 1 : ""
                          }`,
                          display: hideShowCommissionMenu(item, userDetails),
                        }}
                      >
                        <NavLink
                          to={item.path}
                          className={`nav-link text-start ${getThemedTextColor()} ${
                            item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                          }`}
                          aria-current="page"
                        >
                          <span
                            className="sidebar_Icons"
                            style={{
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                            onClick={() => openMenuwithLabel(item)}
                          >
                            {item?.isManage ? (
                              item?.icon
                            ) : (
                              <img src={item.icon} alt="squiggle" />
                            )}
                            {t(item.label)}
                          </span>
                        </NavLink>
                      </li>
                    );
                  }
                  // else {
                  //   return (
                  //     <li key={item.key} className="nav-item m-2">
                  //       <NavLink
                  //         type="button"
                  //         to={"#"}
                  //         className={`nav-link text-start ${getThemedTextColor()}
                  //         ${
                  //           item.vissbleOnSmallScrren ? "" : "d-none d-md-block"
                  //         }`}
                  //         aria-current="page"
                  //         data-bs-toggle="collapse"
                  //         data-bs-target={"#collapseExample" + item.key}
                  //         aria-expanded="false"
                  //         aria-controls={"collapseExample" + item.key}
                  //       >
                  //         <span
                  //           className={` ${
                  //             Location.pathname.includes("/app/manage")
                  //               ? "activeSubmenu"
                  //               : ""
                  //           }`}
                  //           style={{
                  //             color: "white",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             gap: 10,
                  //             fontSize: 19,
                  //           }}
                  //         >
                  //           {item.icon}
                  //           {t(item.label)}
                  //         </span>
                  //       </NavLink>
                  //       <ul
                  //         className={`c-none-list-style collapse p-x-2 ${
                  //           Location.pathname.includes("/app/manage")
                  //             ? "show"
                  //             : ""
                  //         }`}
                  //         id={"collapseExample" + item.key}
                  //       >
                  //         {item.subMenu.map((nestedItem, i) => {
                  //           return (
                  //             <li
                  //               key={nestedItem.key}
                  //               className="nav-item "
                  //               onClick={onClickMenuItem}
                  //               style={{
                  //                 backgroundColor: `${
                  //                   nestedItem?.path === Location?.pathname
                  //                     ? "#00CE3F"
                  //                     : ""
                  //                 }`,

                  //                 fontFamily: `${
                  //                   nestedItem?.path === Location?.pathname
                  //                     ? "Manrope-Bold"
                  //                     : "Manrope-Regular"
                  //                 }`,
                  //                 fontSize: `${
                  //                   nestedItem?.path === Location?.pathname
                  //                     ? "18px"
                  //                     : "18px"
                  //                 }`,
                  //                 zIndex: `${
                  //                   nestedItem?.path === Location?.pathname
                  //                     ? 1
                  //                     : ""
                  //                 }`,
                  //               }}
                  //             >
                  //               <NavLink
                  //                 to={nestedItem.path}
                  //                 className={`nav-link text-start ${getThemedTextColor()} ${
                  //                   nestedItem.vissbleOnSmallScrren
                  //                     ? ""
                  //                     : "d-none d-md-block"
                  //                 }`}
                  //                 aria-current="page"
                  //               >
                  //                 <div
                  //                   style={{
                  //                     display: "flex",
                  //                     gap: 10,
                  //                     marginLeft: 20,
                  //                   }}
                  //                 >
                  //                   {nestedItem?.path === Location?.pathname ? (
                  //                     <span className="dropend">
                  //                       <span className="dropdown-navlink  dropdown-toggle "></span>
                  //                     </span>
                  //                   ) : (
                  //                     ""
                  //                   )}
                  //                   <span
                  //                     style={{
                  //                       color: "white",
                  //                       display: "flex",
                  //                       alignItems: "center",
                  //                       justifyContent: "center",
                  //                     }}
                  //                   >
                  //                     {nestedItem.label}
                  //                   </span>
                  //                 </div>
                  //               </NavLink>
                  //             </li>
                  //           );
                  //         })}
                  //       </ul>
                  //     </li>
                  //   );
                  // }
                })}
              </ul>
              {/* MENU SECTION FOR SIDEBAR + LABEL */}
              <Menu
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: "10px",
                      width: "150px",
                    },
                  },
                }}
                // anchorEl={anchorEl}
                open={openMenu.withLabel}
                onClose={() => setOpenMenu({ ...openMenu, withLabel: false })}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{
                  marginLeft: "12.5rem",
                  marginTop: "14rem",
                }}
                sx={{
                  "& .MuiList-root": {
                    background: "#3D4740",
                    opacity: 0.9,
                    color: "white",
                  },
                  "& .MuiMenuItem-root": {
                    gap: 2,
                  },
                }}
              >
                {Manage_Submenu?.map((item) => (
                  <>
                    <MenuItem
                      key={item?.key}
                      onClick={() => handleClickMenuWithlabel(item?.path)}
                    >
                      {item?.icon} {item?.label}
                    </MenuItem>
                    <Divider
                      key={item?.key}
                      style={{ border: "1px solid white" }}
                    />
                  </>
                ))}
              </Menu>
              <ul
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "208px",
                  backgroundColor: "#3D4740",
                  height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                className="nav flex-column mb-auto"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClickOpenProfileOptions}
              >
                <li className="nav-item m-2">
                  <NavLink className="nav-link text-center" aria-current="page">
                    <span
                      className="nav-label"
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          border: `2px solid #00CE3F`,
                          height: "52px",
                          width: "52px",
                        }}
                        src={
                          user?.type == "Internal"
                            ? user?.profilePhotoUrl || null
                            : `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${user?.profilePhotoUrl}` ||
                              ""
                        }
                      />
                      <Typography
                        sx={{
                          marginLeft: "1rem",
                          marginTop: "0.2rem",
                        }}
                      >
                        {`${user?.firstName || ""} ${user?.lastName || ""}`}
                      </Typography>
                    </span>
                  </NavLink>
                </li>
              </ul>

              <Menu
                slotProps={{
                  paper: {
                    sx: { borderRadius: "24px" },
                  },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseProfileOptions}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{ marginLeft: "0.5rem" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiList-root": {
                    background: "#3D4740",
                    opacity: 0.9,
                    color: "white",
                  },
                }}
              >
                <MenuItem onClick={handleNavigateToProfilePage}>
                  My Account
                </MenuItem>

                {userDetails?.role !== USER_ROLE?.INTRODUCER ? (
                  <MenuItem onClick={handleNavigateToTechSupportPage}>
                    Tech Support
                  </MenuItem>
                ) : null}

                {userDetails?.role === USER_ROLE?.CONSULTANT && (
                  <MenuItem onClick={handleNavigateToCommissionPage}>
                    My Commissions
                  </MenuItem>
                )}

                <MenuItem className="green" onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            </nav>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
});

export default SideBar;
