import CallMadeIcon from "@mui/icons-material/CallMade";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import AlertComponent from "../../../../components/alert";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { xeroPay } from "../../../../thunks/auth";
import { updateInstructionProp } from "../../../../thunks/instruction";
import { COMMISSION_STATUS, USER_ROLE } from "../../../../utils/constant";
import ClientCareLetter from "../ClientCareLetter";
import ConsultantIntroducerCommissionInvoice from "../ConsultantIntroducerCommissionInvoice";
import CreateNewTask from "../CreateNewTask";
import MeetingLogs from "../MeetingLogs";
import TransferConsultantDialog from "../TransferConsultantDialog";
import TransferIntroducerDialog from "../TransferIntroducerDialog";
import UpdateInstructionStatusDialog from "../UpdateInstructionStatusDialog";
const InstructionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const instructionProducts = useSelector(
    (state) => state?.instruction?.instructionProductList
  );
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const instructionData = useSelector(
    (state) => state.instruction.instructionDashboardData
  );
  const lookups = useSelector((state) => state?.lookupData);
  let {
    "Instruction Status": instructionStatusList,
    "Invoice Status": invoiceStatusList,
  } = lookups || {};
  const [showMeetingLogs, setShowMeetingLogs] = useState(false);
  const [showClientLetter, setShowClientLetter] = useState(false);
  const [isCommissionDialog, setCommissionDialog] = useState(false);
  const [isTransferConsultant, setIsTranferConsultant] = useState(false);
  const [isTransferIntroducer, setIsTranferIntroducer] = useState(false);
  const [isNewTask, setIsNewTask] = useState(false);
  const [isconfirm, setIsconfirm] = useState({
    isGenerateInvoice: false,
  });
  const [isLoader, setLoader] = useState(false);
  const [statusKey, setStatusKey] = useState(null);
  const [updateInstructionStatusData, setUpdateInstructionStatusData] =
    useState([]);
  const [isInstructionStatus, setIsInstructionStatus] = useState(false);

  /* Confirmation method to generate invoice */
  const handleisConfirm = () => {
    setIsconfirm({
      ...isconfirm,
      isGenerateInvoice: true,
    });
  };

  /* Method to open Xero window on click of Xero Button in left side section */
  const XeroGenerateInvoice = async () => {
    generateXeroInvoice();
    // localStorage.setItem("tempXeroInstructionId", instructionData?.id);
    // setLoader(true);
    // window.open(
    //   `${API_URL}${API_ROUTES_PATHS.XERO_LOGIN}`,
    //   "_self",
    //   "height=200,width=150"
    // );
    // setIsconfirm({
    //   ...isconfirm,
    //   isGenerateInvoice: false,
    // });
  };
  /* Method to generate XERO Invoice */
  const generateXeroInvoice = async () => {
    setIsconfirm({ ...isconfirm, isGenerateInvoice: false });
    try {
      const payload = {
        id: instructionData?.id,
        party: localStorage.getItem("xeroParty") || "",
        instructionCommissionId: 0,
      };
      setLoader(true);
      const response = await dispatch(xeroPay(payload)).unwrap();
      if (response) {
        dispatch(
          showSnackbar({
            message: `${response?.message ? response?.message : response}`,
            severity: "success",
          })
        );
        setLoader(false);
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
    }
  };

  /* Method to open instruction status menu */
  const handleOpenInstructionStatus = (type) => {
    switch (type) {
      case "instructionStatus":
        setStatusKey(type);
        setUpdateInstructionStatusData(instructionStatusList);
        setIsInstructionStatus(true);

        break;

      case "invoice":
        setStatusKey(type);
        setUpdateInstructionStatusData(invoiceStatusList);
        setIsInstructionStatus(true);
        break;

      case "isCommission":
        setStatusKey(type);
        setUpdateInstructionStatusData(COMMISSION_STATUS);
        setIsInstructionStatus(true);
        break;

      default:
        return null;
    }
  };

  // Method to update milestone
  const handleUpdateMilestone = async (data) => {
    const payload = {
      id: instructionData?.id,
      milestoneId: data?.id,
    };
    try {
      setLoader(true);
      const response = await dispatch(updateInstructionProp(payload)).unwrap();
      if (response) {
        setLoader(false);
        navigate("/app/instructions/list");
        dispatch(
          showSnackbar({
            message: "Milestone updated successfully",
            severity: "success",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  // Method to get total client fees
  const getTotalClientFees = (totalCost = 0, disbursement = 0) => {
    const total = Number(totalCost || 0) + Number(disbursement || 0);
    return total?.toFixed(2);
  };

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}

      {/* Dialog for Meeting Logs */}
      {showMeetingLogs && (
        <MeetingLogs
          open={showMeetingLogs}
          handleClose={() => setShowMeetingLogs(false)}
          instructionId={instructionData?.id}
        />
      )}

      {/* Dialog for Client Care Letter */}
      {showClientLetter && (
        <ClientCareLetter
          open={showClientLetter}
          handleClose={() => setShowClientLetter(false)}
          instructionId={instructionData?.id}
        />
      )}

      {/* Dialog for Consultant Introducer Commission Invoice */}
      {isCommissionDialog && (
        <ConsultantIntroducerCommissionInvoice
          open={isCommissionDialog}
          handleClose={() => setCommissionDialog(false)}
          instructionId={instructionData?.id}
        />
      )}

      {/* Dialog for Transfer Instruction to Consultant */}
      {isTransferConsultant && (
        <TransferConsultantDialog
          open={isTransferConsultant}
          handleClose={() => setIsTranferConsultant(false)}
          instructionId={instructionData?.id}
        />
      )}
      {/* Dialog for Transfer Instruction to Introducer */}
      {isTransferIntroducer && (
        <TransferIntroducerDialog
          open={isTransferIntroducer}
          handleClose={() => setIsTranferIntroducer(false)}
          instructionId={instructionData?.id}
        />
      )}

      {/* Dialog for New Task Create */}
      {isNewTask && (
        <CreateNewTask
          open={isNewTask}
          handleClose={() => setIsNewTask(false)}
          instructionId={instructionData?.id}
          products={instructionProducts}
        />
      )}

      {/* Confirmation dialog for XERO invoice generation */}
      {isconfirm?.isGenerateInvoice && (
        <AlertComponent
          open={isconfirm}
          handleSuccess={XeroGenerateInvoice}
          handleClose={() =>
            setIsconfirm({ ...isconfirm, isGenerateInvoice: false })
          }
          message="Are you sure you want to generate an invoice?"
        />
      )}

      {/* Dialog for Update Instruction status Dialog */}
      {isInstructionStatus && (
        <UpdateInstructionStatusDialog
          open={isInstructionStatus}
          handleClose={() => setIsInstructionStatus(false)}
          data={updateInstructionStatusData}
          statusKey={statusKey}
          isIntroducerCommissionShowOrNot={
            instructionData?.isIntroducerCommissionShowOrNot
          }
          isIntroducersCommissionApplicable={
            instructionData?.isIntroducersCommissionApplicable
          }
          statusId={instructionData?.instructionStatusLookupId}
        />
      )}
      <Grid
        item
        xs={2.5}
        className="InstructionDashboardWrapper instruction_dashboard_scrollbar"
        style={{
          overflow: "auto",
          height: "100vh",
          marginLeft: "-12px",
          paddingTop: "10px",
          marginRight: "10px",
        }}
      >
        <Grid container spacing={2}>
          {/* Back Button Section --> navigate to instruction list */}
          <Grid item xs={2}>
            <img
              src={BackButton}
              onClick={() => {
                // dispatch(ClearTaskData());
                navigate("/app/instructions/list");
              }}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            />
          </Grid>

          <Grid item xs={8}>
            {/* Client names section --> mapped client & display's firstName + lastName */}
            {instructionData?.InstructionClients?.length > 0 &&
              instructionData?.InstructionClients?.map((d, ind) => (
                <>
                  <Grid style={{ marginBottom: "1.5rem" }} key={d?.id}>
                    <Typography
                      className="normal_normal_600_12_Manrope green client1Heading"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      Client {ind + 1}
                    </Typography>
                    <Typography
                      className="normal_normal_18_Manrope dark_grey singleClientClick"
                      onClick={() =>
                        navigate(`/app/client/update-client/${d?.id}`, {
                          state: {
                            fromCase: true,
                            caseId: instructionData?.id,
                          },
                        })
                      }
                    >
                      {`${d?.firstName || "NA"} ${d?.lastName || "NA"}`}
                    </Typography>
                  </Grid>
                </>
              ))}

            {/* Divider Section */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>

            {/* Instruction type section */}
            <Grid item xs={12} style={{ marginBottom: "1.3rem" }}>
              <Typography className="normal_normal_600_12_Manrope green">
                Type
              </Typography>

              <Typography className="normal_normal_18_Manrope dark_grey">
                {`${instructionData?.applicationType} / ${
                  instructionData?.actualSourceTypeName
                    ? instructionData?.actualSourceTypeName
                    : instructionData?.sourceType
                }` || "NA"}
              </Typography>
            </Grid>

            {/* Milestone section */}
            <Grid item xs={12} style={{ marginBottom: "1.3rem" }}>
              <Typography className="normal_normal_600_12_Manrope green">
                Milestone
              </Typography>
              <Typography className="normal_normal_18_Manrope dark_grey">
                {instructionData?.milestone || "NA"}
              </Typography>

              {instructionData?.nextMilestone &&
                (userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
                  <>
                    <Typography
                      className="normal_normal_600_12_Manrope_Update_Milestone"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginTop: "0.5rem",
                        animation: "blink 1s infinite",
                      }}
                      onClick={() =>
                        handleUpdateMilestone(instructionData?.nextMilestone)
                      }
                    >
                      {instructionData?.nextMilestone &&
                        `Update Milestone to ${
                          instructionData?.nextMilestone?.name || "NA"
                        }`}
                    </Typography>
                  </>
                ) : null)}
            </Grid>

            {/* Instruction status section --> here only super admin & office admin 
            can update instruction status by passing status of type === instructionStatus
            */}
            <Grid item xs={12} style={{ marginBottom: "1.3rem" }}>
              <Typography className="normal_normal_600_12_Manrope green" mb={1}>
                Status{" "}
                <Tooltip title="click to change status">
                  <CallMadeIcon
                    onClick={() =>
                      handleOpenInstructionStatus(
                        "instructionStatus",
                        instructionData?.instructionStatusLookupId
                      )
                    }
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      color: "#00ce3f",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </Typography>
              <Typography className="normal_normal_18_Manrope dark_grey">
                {instructionData?.status || "NA"}
              </Typography>
            </Grid>

            {/* Divider Section */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>

            {/* Section to show meeting logs for instruction */}
            <Grid
              item
              xs={12}
              style={{ marginBottom: "1.3rem" }}
              textAlign={"center"}
            >
              <Button
                fullWidth
                size="small"
                variant="outlined"
                className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                style={{
                  borderRadius: "24px",
                  border: "1px solid #01CE3F",
                }}
                onClick={() => setShowMeetingLogs(true)}
              >
                Meeting Log
              </Button>
            </Grid>

            {/* Divider Section */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>

            {/* Selected plan details section */}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1.3rem",
              }}
            >
              <Typography className="normal_normal_600_12_Manrope green">
                Plan(s) Selected
              </Typography>

              <Typography className="normal_normal_18_Manrope dark_grey">
                {instructionData?.InstructionPlan
                  ?.InstructionPlanSelectionsAndIndividualProducts || "NA"}
              </Typography>
            </Grid>

            {/* Selected plan total price section */}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1.3rem",
              }}
            >
              <Typography className="normal_normal_600_12_Manrope green">
                Squiggle Fees
              </Typography>
              <Typography>
                £{" "}
                {instructionData?.InstructionPlan
                  ? Number(instructionData?.InstructionPlan?.totalCost).toFixed(
                      2
                    )
                  : ""}
              </Typography>
            </Grid>

            {/* Selected plan total disbursement section */}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1.3rem",
              }}
            >
              <Typography className="normal_normal_600_12_Manrope green">
                Disbursement
              </Typography>
              <Typography>
                £{" "}
                {instructionData?.InstructionPlan
                  ? Number(
                      instructionData?.InstructionPlan?.totalDisbursement
                    ).toFixed(2)
                  : ""}
              </Typography>
            </Grid>

            {/* Selected client to pay section */}
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "1.3rem",
              }}
            >
              <Typography className="normal_normal_600_12_Manrope green">
                Total Client Fees
              </Typography>
              <Typography>
                £{" "}
                {getTotalClientFees(
                  instructionData?.InstructionPlan?.totalCost,
                  instructionData?.InstructionPlan?.totalDisbursement
                )}
              </Typography>
            </Grid>

            {/* Payment Status section --> only Super admin & office admin can update 
                payment status */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
                  <Grid
                    item
                    xs={9}
                    style={{
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Grid style={{ display: "flex", gap: 10 }}>
                      <Typography
                        className="normal_normal_600_12_Manrope green"
                        mb={1}
                      >
                        Payment Status
                      </Typography>
                      <Tooltip title="click to change status">
                        <CallMadeIcon
                          onClick={() => handleOpenInstructionStatus("invoice")}
                          style={{
                            fontSize: "16px",
                            cursor: "pointer",
                            color: "#00ce3f",
                          }}
                        />
                      </Tooltip>
                    </Grid>

                    {instructionData?.invoice?.invoiceStatusLookupName ? (
                      <Typography className="normal_normal_18_Manrope dark_grey">
                        {instructionData?.invoice?.invoiceStatusLookupName}
                      </Typography>
                    ) : (
                      "NA"
                    )}
                  </Grid>
                ) : null}
                {/* Only super admin & office admin can genrate invoice on XERO */}
                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
                  !instructionData?.invoice ? (
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        className="normal_normal_medium_14_Manrope xero_button"
                        variant="contained"
                        style={{
                          boxShadow: "none",
                          backgroundColor: "#14C7E1",
                          borderRadius: "24px",
                        }}
                        onClick={() => handleisConfirm()}
                      >
                        XERO
                      </Button>
                    </Grid>
                  ) : null
                ) : null}
              </Grid>
            </Grid>

            {/* If role === Super admin and office admin */}
            {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
            userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Grid style={{ display: "flex", gap: 10 }}>
                      <Typography
                        className="normal_normal_600_12_Manrope green"
                        mb={1}
                      >
                        Squiggle Marketing Fee (SMF) Applicable
                      </Typography>
                      {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                      userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
                        <Tooltip title="click to change status">
                          <CallMadeIcon
                            onClick={() =>
                              handleOpenInstructionStatus("isCommission")
                            }
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              color: "#00ce3f",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </Grid>

                    <Typography className="normal_normal_18_Manrope dark_grey">
                      {instructionData?.isIntroducersCommissionApplicable
                        ? "Yes"
                        : "No" || "NA"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {/* Divider Section */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>

            {/* Client care letter section */}
            <Grid
              item
              xs={12}
              style={{ marginBottom: "1.3rem", marginTop: "1rem" }}
            >
              <Button
                fullWidth
                size="small"
                variant="outlined"
                disabled={
                  instructionData?.milestone?.toLowerCase() === "prospect"
                }
                onClick={() => {
                  setShowClientLetter(true);
                }}
                className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                style={{
                  borderRadius: "24px",
                  border: "1px solid #00ce3f",
                }}
              >
                Client Care Letter
              </Button>
            </Grid>

            {/* Divider Section */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>

            {/* Commission Button section --> Super Admin & Office admin can view Commission */}
            {userDetails?.role !== USER_ROLE?.CONSULTANT ? (
              <>
                {/* Generate invoices for consultant commission and introducer commission */}
                <Grid
                  item
                  xs={12}
                  style={{ marginBottom: "1.3rem", marginTop: "1rem" }}
                >
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setCommissionDialog(true);
                    }}
                    className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                    style={{
                      borderRadius: "24px",
                      border: "1px solid #00ce3f",
                    }}
                  >
                    Commissions
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    style={{
                      border: "1px solid #00ce3f",
                      marginBottom: "1.3rem",
                    }}
                  />
                </Grid>
              </>
            ) : null}

            {/* Introducer name section */}
            <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
              <Typography
                className="normal_normal_600_12_Manrope green client1Heading"
                style={{ marginBottom: "0.5rem" }}
              >
                Introducer
              </Typography>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className="normal_normal_18_Manrope dark_grey singleClientClick">
                  {instructionData?.introducerOfInstruction?.role ===
                  USER_ROLE?.INTRODUCER
                    ? `${
                        instructionData?.introducerOfInstruction?.firstName ||
                        "NA"
                      } ${
                        instructionData?.introducerOfInstruction?.lastName ||
                        "NA"
                      }`
                    : "NA"}
                </Typography>
                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                USER_ROLE?.OFFICE_ADMIN
                  ? !["Prospect", "Not Instructed"]?.includes(
                      instructionData?.milestone
                    ) && (
                      <Tooltip title="click to update introducer">
                        <IconButton
                          onClick={() => setIsTranferIntroducer(true)}
                        >
                          <TransferWithinAStationIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  : null}
              </Grid>
            </Grid>

            {/* Section for consultant name + transfer instruction section */}
            <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
              <Typography
                className="normal_normal_600_12_Manrope green client1Heading"
                style={{ marginBottom: "0.5rem" }}
              >
                Consultant
              </Typography>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className="normal_normal_18_Manrope dark_grey singleClientClick">
                  {`${instructionData?.ownerOfInstruction?.firstName || "NA"} ${
                    instructionData?.ownerOfInstruction?.lastName || "NA"
                  }`}
                </Typography>

                {userDetails?.role === USER_ROLE?.SUPER_ADMIN ||
                USER_ROLE?.OFFICE_ADMIN
                  ? !["Prospect", "Not Instructed"]?.includes(
                      instructionData?.milestone
                    ) && (
                      <Tooltip title="click to update consultant">
                        <IconButton
                          onClick={() => setIsTranferConsultant(true)}
                        >
                          <TransferWithinAStationIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  : null}
              </Grid>
            </Grid>

            {/* Section for Create New Task */}
            <Grid item xs={12}>
              <Divider
                style={{
                  border: "1px solid #00ce3f",
                  marginBottom: "1.3rem",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "1.3rem", marginTop: "1rem" }}
            >
              <Button
                fullWidth
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsNewTask(true);
                }}
                className="meeting_log_btn normal_normal_semibold_14_Manrope green"
                style={{
                  borderRadius: "24px",
                  border: "1px solid #00ce3f",
                }}
              >
                Create Task
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InstructionDetails;
