import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGridComponent from "../../../components/DataGridComponent";
import Loader from "../../../components/Loader";
import AlertComponent from "../../../components/alert";
import { showSnackbar } from "../../../slice/snackbarSlice";
import { xeroPay } from "../../../thunks/auth";
import {
  getInstructionById,
  getIntroducerConsultantCommission,
  getIsInstructionValidToRaiseBill,
  updateConsultantIntroducerCommissionStatus,
  updateInstructionProp,
} from "../../../thunks/instruction";
import { NOTIFICATION_MESSAGE, USER_ROLE } from "../../../utils/constant";

const ConsultantIntroducerCommissionInvoice = ({
  open,
  handleClose,
  instructionId,
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.currentUser);
  const instructionDetails = useSelector(
    (state) => state?.instruction?.instructionDashboardData
  );

  const [commissionData, setCommissionData] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [isGenerateBill, setIsGenerateBill] = useState(null);
  const [isconfirm, setIsconfirm] = useState({
    isGenrateBill: false,
    isBillToPay: false,
    isDelete: false,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  /* Confirmation method to generate bill */
  const handleisConfirm = () => {
    setIsconfirm({
      ...isconfirm,
      isGenrateBill: true,
    });
  };

  // Method to handleClose bill confirmation
  const handleCloseBillConfirm = () => {
    setSelectedRow(null);
    setIsconfirm({ ...isconfirm, isBillToPay: false });
  };

  /* Confirmation method to pay bill */
  const handleIsConfirmPayBill = (params) => {
    setSelectedRow(params);
    setIsconfirm({
      ...isconfirm,
      isBillToPay: true,
    });
  };

  const handleRedirectBill = (params) => {
    let link = `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${params?.row?.Invoice?.xeroInvoiceId}`;
    window.open(link, "_blank");
  };
  const columns = [
    {
      field: "role",
      headerName: "Role",
      minWidth: 160,
      sortable: false,
    },
    {
      field: "MilestoneOnCommissions",
      headerName: "Milestone",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <Typography>
          {params?.row?.MilestoneOnCommissions?.name || "NA"}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {userDetails?.role === USER_ROLE?.OFFICE_ADMIN ? (
              "####"
            ) : (
              <Typography>
                {Number(params?.row?.amount).toFixed(2) || "NA"}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 180,
      sortable: false,
      renderCell: (params) => (
        <Typography align="center">{params?.row?.status || "-"}</Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <Grid xs={10}>
          <Button
            onClick={() => handleIsConfirmPayBill(params)}
            disabled={params?.row?.status === "Paid" || !params?.row?.Invoice}
            size="small"
            // fullWidth
            variant="outlined"
            className="meeting_log_btn normal_normal_semibold_14_Manrope green"
            style={{
              textTransform: "none",
              borderRadius: "24px",
              margin: 1,
              marginRight: 10,
              border: `${
                params?.row?.status === "Paid" || !params?.row?.Invoice
                  ? ""
                  : "1px solid #00ce3f"
              }`,
              padding: "2px 20px",
            }}
          >
            {params?.row?.status === "Paid" ? "Paid" : "Pay"}
          </Button>
          {/* Redirect to bill btn */}
          {params?.row?.Invoice && (
            <Button
              // disabled={params?.row?.status === "Paid"}
              onClick={() => handleRedirectBill(params)}
              size="small"
              // fullWidth
              variant="outlined"
              className="meeting_log_btn normal_normal_semibold_14_Manrope green"
              style={{
                textTransform: "none",
                borderRadius: "24px",
                margin: 1,
                marginRight: 10,
                border: "1px solid #00ce3f",
                padding: "2px 20px",
              }}
            >
              Xero Bill
            </Button>
          )}
          {/* DELETE BUTTON SECTION  */}
          {params?.row?.role === "Introducer" &&
            !params?.row?.isBillPayableRaised &&
            params?.row?.status === "Not Paid" && (
              <IconButton onClick={() => handleClickRow(params)}>
                <DeleteIcon className="red" />
              </IconButton>
            )}
        </Grid>
      ),
    },
  ];

  const getCommissions = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getIntroducerConsultantCommission(instructionId)
      ).unwrap();
      if (response) {
        setCommissionData(response);
        setLoader(false);
      } else {
        setCommissionData(null);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  /* Pay Bill by proving xeroParty, instruction ID, Commission ID */
  const payBill = () => {
    const party = selectedRow?.row?.role || "Custom";
    const commissionID = selectedRow?.row?.id;
    generateXeroInvoice(party, commissionID);

    // setLoader(true);
    // localStorage.setItem("xeroParty", params?.row?.role);
    // localStorage.setItem("tempXeroInstructionId", instructionId);
    // localStorage.setItem("instructionCommissionId", params?.row?.id);
    // window.open(
    //   `${API_URL}${API_ROUTES_PATHS.XERO_LOGIN}`,
    //   "_self",
    //   "height=200,width=150"
    // );
  };

  /* Method to generate XERO Invoice */
  const generateXeroInvoice = async (party, commissionID) => {
    setIsconfirm({ ...isconfirm, isGenrateBill: false, isBillToPay: false });
    try {
      const payload = {
        id: instructionId,
        party: party || "Custom",
        instructionCommissionId: commissionID || 0,
      };
      setLoader(true);
      const response = await dispatch(xeroPay(payload)).unwrap();
      if (response) {
        setSelectedRow(null);
        dispatch(
          showSnackbar({
            message: `${response?.message ? response?.message : response}`,
            severity: "success",
          })
        );
        setLoader(false);
        handleClose();
      }
    } catch (error) {
      setSelectedRow(null);
      dispatch(
        showSnackbar({
          message: `${error?.message}`,
          severity: "error",
        })
      );
      setLoader(false);
      handleClose();
    }
  };

  /* Method to check is instruction is valid to raise bill or not */
  const checkIsBillToRaise = async () => {
    try {
      const response = await dispatch(
        getIsInstructionValidToRaiseBill(instructionId)
      ).unwrap();
      if (response) {
        setIsGenerateBill(response);
      } else {
        setIsGenerateBill(null);
      }
    } catch (error) {
      setIsGenerateBill(error);
      return error;
    }
  };
  //METHOD TO GET DATA TO DELETE ROW
  const handleClickRow = (params) => {
    setSelectedRow(params);
    setIsconfirm({ ...isconfirm, isDelete: true });
  };

  //METHOD TO DELETE ROW
  const handleDeleteRow = async () => {
    try {
      setIsconfirm({ ...isconfirm, isDelete: false });
      setLoader(true);
      const payload = {
        id: selectedRow?.row?.id,
        isDelete: true,
      };
      let response = await dispatch(
        updateConsultantIntroducerCommissionStatus(payload)
      ).unwrap();
      if (response) {
        handleClose();
        setLoader(false);
        dispatch(
          showSnackbar({
            message: `${NOTIFICATION_MESSAGE?.ACTION_COMPLETE_SUCESS}`,
            severity: "success",
          })
        );
      }
      await dispatch(
        getInstructionById({
          id: instructionDetails?.id,
        })
      );
    } catch (error) {
      setLoader(false);
      handleClose();
      dispatch(
        showSnackbar({
          message: `${NOTIFICATION_MESSAGE.SOMETHING_WENT_WRONG}`,
          severity: "error",
        })
      );
    }
  };

  const copyRef = () => {
    // creating data to copy
    const instructionRef = instructionDetails?.id;
    const clientNameForRef = instructionDetails?.InstructionClients?.reduce(
      (accumulator, data) => {
        const fullName = `${data?.firstName} ${data?.middleName} ${data?.lastName}`;
        return accumulator === "" ? fullName : `${accumulator}, ${fullName}`;
      },
      ""
    );
    const refDetails = clientNameForRef + "_" + instructionRef;
    // copy details to clipboard
    navigator?.clipboard
      .writeText(refDetails)
      .then(() => {
        dispatch(
          showSnackbar({
            message: NOTIFICATION_MESSAGE?.LINK_COPY_SUCCESS,
            severity: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          showSnackbar({
            message: "Unable to copy text to clipboard",
            severity: "error",
          })
        );
        console.error("Unable to copy text to clipboard", err);
      });
  };

  useEffect(() => {
    getCommissions();
    checkIsBillToRaise();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"lg"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
      >
        {/* Loader component */}
        {isLoader && <Loader />}

        {/* Confirmation dialog for generating bill */}
        {isconfirm?.isGenrateBill && (
          <AlertComponent
            open={isconfirm?.isGenrateBill}
            handleSuccess={payBill}
            handleClose={() =>
              setIsconfirm({ ...isconfirm, isGenrateBill: false })
            }
            message="Are you sure you want to generate the bill?"
          />
        )}
        {/* Confirmation dialog for delete bill */}
        {isconfirm?.isDelete && (
          <AlertComponent
            open={isconfirm?.isDelete}
            handleSuccess={handleDeleteRow}
            handleClose={() => setIsconfirm({ ...isconfirm, isDelete: false })}
            message="Are you sure you want to delete the bill?"
          />
        )}

        {/*  Confirmation dialog for pay bill */}
        {isconfirm?.isBillToPay && (
          <AlertComponent
            open={isconfirm?.isBillToPay}
            handleSuccess={payBill}
            handleClose={handleCloseBillConfirm}
            message="Are you sure you want to pay the bill?"
          />
        )}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle style={{ display: "flex", gap: 20, alignItems: "center" }}>
          <Typography
            className="serif_display_regular_26 green"
            textAlign={"center"}
          >
            Commissions
          </Typography>
          {isGenerateBill?.data ? (
            <Button
              onClick={() => handleisConfirm()}
              size="small"
              variant="outlined"
              className="meeting_log_btn normal_normal_semibold_14_Manrope green"
              style={{
                borderRadius: "24px",
                margin: 1,
                border: "1px solid #00ce3f",
              }}
            >
              Generate Bill
            </Button>
          ) : (
            <Grid>
              <FormLabel style={{ color: isGenerateBill?.color }}>
                {isGenerateBill?.message}
              </FormLabel>
              {isGenerateBill?.color === "#00CE3F" && (
                <Tooltip title="Copy reference" placement="top">
                  <IconButton
                    aria-label=""
                    onClick={copyRef}
                    sx={{ marginLeft: "10px" }}
                  >
                    <CopyAll fontSize="large" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
        </DialogTitle>

        <DialogContent dividers={true}>
          <Grid container spacing={1}>
            {/* Data Grid For Consultant */}
            {commissionData?.length > 0 ? (
              <Grid
                item
                xs={12}
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <DataGridComponent
                  columns={columns}
                  rows={commissionData || []}
                  onRowClick={() => null}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography align="center" className="normal_normal_18_Manrope">
                  Commission not found.
                </Typography>
                <Divider />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConsultantIntroducerCommissionInvoice;
