import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { getMilestoneHistory } from "../../../thunks/instruction";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../../../components/Loader";
import { showSnackbar } from "../../../slice/snackbarSlice";

const MilestoneHistoryDialog = ({ open, handleClose, instructionId }) => {
  const dispatch = useDispatch();
  const [milestoneHistoryList, setMilestoneHistoryList] = useState([]);
  const [isLoader, setLoader] = useState(false);

  const handleGetMilestoneHistory = async () => {
    try {
      setLoader(true);
      const response = await dispatch(
        getMilestoneHistory(instructionId)
      ).unwrap();
      if (response?.length > 0) {
        setMilestoneHistoryList(response);
      } else {
        setMilestoneHistoryList([]);
      }
      setLoader(false);
    } catch (error) {
      handleClose();
      setLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error.message}`,
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    handleGetMilestoneHistory();
  }, []);

  return (
    <>
      <Dialog
        sx={{ border: 1 }}
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        PaperProps={{
          sx: {
            borderRadius: "28px",
            paddingBottom: 0,
            width: 300,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          {" "}
          <Typography
            className="serif_display_regular_20 green"
            textAlign={"center"}
          >
            Milestone History
          </Typography>
        </DialogTitle>
        {/* {isLoader && <div>Loading data</div>} */}
        {isLoader && <Loader />}
        <DialogContent dividers={true} style={{ padding: 5, margin: 0 }}>
          <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
            {!isLoader && milestoneHistoryList.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell className="normal_normal_600_16_Manrope green">
                    Milestone
                  </TableCell>
                  <TableCell className="normal_normal_600_16_Manrope green">
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {milestoneHistoryList.map((row, index) => {
                const formattedDate = moment(new Date(row?.updatedAt)).format(
                  "DD/MM/YYYY"
                );

                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="normal_normal_16_Manrope"
                    >
                      {row?.milestoneCurrent?.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="normal_normal_16_Manrope"
                    >
                      {formattedDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MilestoneHistoryDialog;
