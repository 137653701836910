import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../assets/images/BUTTON_Back.svg";
import DefaultImage from "../../../../assets/images/Squiggle_S_Square_Green.png";
import Loader from "../../../../components/Loader";
import AlertComponent from "../../../../components/alert";
import { showSnackbar } from "../../../../slice/snackbarSlice";
import { uploadFile } from "../../../../thunks/documents";
import { getSharedEmailIdData } from "../../../../thunks/email_template";
import { getAllTasks } from "../../../../thunks/task";
import {
  deactivateUser,
  getConsultant,
  getIntroducer,
  getUserDataById,
  updateUser,
  xeroOptionSet,
  xeroSuspenseData,
} from "../../../../thunks/user";
import {
  DATE_FORMAT,
  IMG_BASE_URL,
  IS_VAT_VERIFIED_DATA,
  NOTIFICATION_MESSAGE,
  OFFICE_ADMIN_PHONE_NUMBER,
  USER_ROLE,
  VALIDATION_MSG,
  VAT_NUMBER_START,
} from "../../../../utils/constant";
import {
  emailValidation,
  getUniqueArray,
  vatNumberValidation,
} from "../../../../utils/util";
import { formValidation } from "./formValidation";

const UpdateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);
  const lookups = useSelector((state) => state?.lookupData);
  let {
    Role: roles,
    Gender: gender,
    Salutation: salutation,
    Country: country,
    UserAccess: userAccess,
  } = lookups || {};

  const UserAccessLabel = lookups?.UserAccess?.[0]?.label;
  const allConsultantArray = [
    {
      id: 1000,
      firstName: "All",
      lastName: "",
      role: "",
      isActive: true,
      email: "",
      phoneNumber: "",
      scheduling_url: "",
      calendlyUserUri: "",
    },
  ];
  const consultants = useSelector((state) => state?.user?.consultantsData);
  const tempProfilePhotoUrl = useSelector(
    (state) => state?.user?.selectedUserData?.profilePhotoUrl
  );

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    role: "",
    rolesLookupId: "",
    type: "",
    firstName: "",
    lastName: "",
    middleName: "",
    isActive: null,
    isVatVerified: false,
    permissions: { lookupId: null, isPermission: false },
    dob: new Date(),
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    phoneNumber: "",
    salutation: "",
    gender: "",
    TaskUser: [],
    ConsultantName: [],
    SharedMails: [],
    profilePhotoUrl: "",
    vatNumber: null,
    xeroTrackingOptions: null,
    xeroSuspenseAccountInfo: null,
    isCommissionApplicable: false,
    isCampaignHead: false,
    campaignSourceType: null,
    campaignLookupId: null,
    isLogin: true,
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [isChangeRole, setIsChangeRole] = useState(false);
  const [tempRoleState, setTempRoleState] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isDeactivateDialog, setDeactivateDialog] = useState(false);
  const [isUpdateUserDialog, setUpdateUserDialog] = useState(false);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [userImage, setUserImage] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [xeroOption, setXeroOption] = useState([]);
  const [xeroLoader, setXeroLoader] = useState(false);
  const [xeroSuspenseUser, SetXeroSuspenseUser] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [xeroSupenseLoader, SetXeroSupenseLoader] = useState(false);

  /* method to handleChange role form fields */
  const handleChangeFormFields = (value, key) => {
    setUserData({
      ...userData,
      [key]: value,
    });
    if (key === "isCommissionApplicable" && value === "false") {
      setUserData({
        ...userData,
        vatNumber: null,
        isVatVerified: false,
        isCommissionApplicable: "false",
      });
    }
    setIsChangeRole(false);
  };

  /* Method to handleChange role */
  const handleRoleChange = (e) => {
    setIsChangeRole(true);
    setTempRoleState(e?.target?.value);
  };

  /* Method to handleChange Address fields */
  const handleChangeAddressFields = (value, key) => {
    setUserData({
      ...userData,
      address: {
        ...userData?.address,
        [key]: value,
      },
    });
  };

  /* Method to handleChange Products field */
  const handleChangeTasks = (value) => {
    const removeAddedTask = tasks.filter((task) => task?.id !== value?.id);
    setTasks(removeAddedTask);
    const findDuplicate = userData?.TaskUser.filter(
      (data) => data?.id === value?.id
    );
    // If block to handle null and duplicate id's
    if (value === null || findDuplicate?.length > 0) {
      setSelectedTask(null);
      if (findDuplicate?.length > 0) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Task already present",
            severity: "error",
          })
        );
      }
    } else {
      setSelectedTask(value);
      setUserData({ ...userData, TaskUser: [...userData?.TaskUser, value] });
    }
  };

  /* Method to delete single task */
  const handleDeleteTask = (d) => {
    const filtered = userData?.TaskUser.filter((obj) => {
      return obj.id !== d?.id;
    });
    setUserData({ ...userData, TaskUser: filtered });
    //ADD DELETED TASKS AGAIN IN DROPDOWN
    const AddRemovedTask = userData?.TaskUser.filter(
      (task) => task?.id === d?.id
    );
    setTasks([...tasks, AddRemovedTask[0]]);
  };

  /* Method to handleChange Consultants field */
  const handleChangeConsultants = (value) => {
    const findDuplicate = userData?.ConsultantName.filter(
      (data) => data?.suserId === value?.id
    );

    if (value?.firstName === "All") {
      const tempConsultant = [];
      consultants?.map((d) => {
        tempConsultant?.push({
          suserId: d?.id || d?.suserId,
          firstName: `${d?.firstName}`,
          lastName: ` ${d?.lastName}`,
        });
      });
      setUserData({ ...userData, ConsultantName: tempConsultant });
    } else if (value === null || findDuplicate?.length > 0) {
      setSelectedConsultant(null);
      if (findDuplicate?.length > 0) {
        dispatch(
          showSnackbar({
            show: true,
            message: "Consultant already present",
            severity: "error",
          })
        );
      }
    } else {
      let consultants = null;
      consultants = {
        suserId: value?.id || value?.suserId,
        firstName: `${value?.firstName}`,
        lastName: ` ${value?.lastName}`,
      };
      setSelectedConsultant(consultants);
      setUserData({
        ...userData,
        ConsultantName: [...userData?.ConsultantName, consultants],
      });
    }
  };

  // method to delete single consultant
  const handleDeleteConsultant = (d) => {
    const filtered = userData?.ConsultantName.filter((obj) => {
      return obj.suserId !== d?.suserId;
    });
    setUserData({ ...userData, ConsultantName: filtered });
  };

  /* Method to handleChange sharedEmail field */
  const handleChangeSharedEmailId = (value) => {
    // const consultants = [];
    // if (value) {
    //   value?.map((d) => {
    //     consultants?.push({
    //       suserId: d?.id || d?.suserId,
    //       firstName: `${d?.firstName}`,
    //       lastName: ` ${d?.lastName}`,
    //     });
    //   });
    //   setUserData({ ...userData, SharedMails: value });
    // }
    setUserData({ ...userData, SharedMails: value });
  };

  /* Method to Update user --> Open confirmation dialog */
  const handleClickOpenConfirmUpdateUserDialog = () => {
    setUpdateUserDialog(true);
  };

  /* Method to Update user */
  const handleClickUpdateUser = async () => {
    setUpdateUserDialog(false);
    const isValidForm = formValidation(userData);
    if (!isValidForm || dateError) {
      setIsError(true);
    } else {
      setIsError(false);
      let tasksToPassPayload = [];

      if (userData?.TaskUser?.length > 0) {
        userData?.TaskUser?.map((d) => {
          tasksToPassPayload.push({ taskId: d?.taskId || d?.id });
        });
      }

      const introducersToPassPayload = [];
      if (userData?.ConsultantName?.length > 0) {
        // get unique values
        const arrayUniqueByKey = getUniqueArray(
          userData?.ConsultantName,
          "suserId"
        );
        arrayUniqueByKey?.map((d) => {
          introducersToPassPayload.push({
            suserId: d?.suserId,
          });
        });
      }

      const tempRoleLookupId = roles?.filter(
        (d) => d?.value === userData?.role
      );
      const tempUserAccessLookupId = userAccess?.filter(
        (d) => d?.id === userData?.permissions?.lookupId
      );
      const tempSharedEmail = userData?.SharedMails?.map((d) => {
        return { sharedEmailId: d?.sharedEmailId ? d?.sharedEmailId : d?.id };
      });
      try {
        setLoader(true);
        let uploadFileResponse = "";
        // Logic => If userImage available
        if (selectedUserProfile) {
          let formData = new FormData();
          formData.append(
            "myfile",
            selectedUserProfile,
            selectedUserProfile?.name
          );

          const payloadFormData = {
            formData,
            url: `/file/upload`,
          };
          uploadFileResponse = await dispatch(
            uploadFile(payloadFormData)
          ).unwrap();
        }

        const payload = {
          id: params?.id,
          payload: {
            email: userData?.email,
            password: userData?.password,
            role: userData?.role,
            rolesLookupId: tempRoleLookupId?.[0]?.id || "",
            type: userData?.type,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            middleName: userData?.middleName,
            // isCommissionApplicable: null,
            isActive: userData?.isActive,
            isVatVerified: userData?.isVatVerified,

            permissions: userData?.permissions?.isPermission
              ? {
                  lookupId: tempUserAccessLookupId?.[0]?.id || 0,
                  isPermission: userData?.permissions?.isPermission || false,
                }
              : undefined,
            dob: userData?.dob,
            address: {
              street: userData?.address?.street,
              city: userData?.address?.city,
              state: userData?.address?.state,
              postalCode: userData?.address?.postalCode,
              country: userData?.address?.country,
            },
            phoneNumber: userData?.phoneNumber,
            salutation: userData?.salutation,
            gender: userData?.gender,
            TaskUser: tasksToPassPayload,
            ConsultantName: introducersToPassPayload,
            MailBoxPermissions: tempSharedEmail || [],
            profilePhotoUrl: selectedUserProfile
              ? uploadFileResponse
              : tempProfilePhotoUrl,
            vatNumber: userData?.isVatVerified ? userData?.vatNumber : null,
            xeroTrackingOptions: userData?.xeroTrackingOptions || null,
            xeroSuspenseAccountInfo: userData?.xeroSuspenseAccountInfo || null,
            isCommissionApplicable:
              userData?.isCommissionApplicable === "true" ? true : false,
            isLogin: userData?.isLogin,
            isCampaignHead: userData?.isCampaignHead,
            campaignLookupId: userData?.campaignLookupId,
          },
        };
        // API to update user
        const response = await dispatch(updateUser(payload)).unwrap();
        if (response?.status === 200) {
          setLoader(false);
          dispatch(
            showSnackbar({
              show: true,
              message: NOTIFICATION_MESSAGE?.USER_UPDATE_SUCCESS,
              severity: "success",
            })
          );
        }
      } catch (error) {
        setLoader(false);
        setUpdateUserDialog(false);
        dispatch(
          showSnackbar({
            show: true,
            message: `${error?.message}`,
            severity: "error",
          })
        );
      }
    }
  };

  /* Method to GetUserBy ID */
  const getUserById = async () => {
    setLoader(true);
    const res = await dispatch(getUserDataById(params.id)).unwrap();
    if (res) {
      const tasksToSetState = [];
      const introducerToSetState = [];
      if (res?.TaskUser?.length > 0) {
        res?.TaskUser.map((d) => {
          tasksToSetState.push({
            id: d?.taskId,
            taskId: d?.taskId,
            name: d?.taskName,
          });
        });
      }
      if (res?.ConsultantName?.length > 0) {
        res?.ConsultantName.map((d) => {
          introducerToSetState.push({
            suserId: d?.suserId,
            firstName: d?.firstName,
            lastName: d?.lastName,
          });
        });
      }
      setUserData({
        ...userData,
        email: res?.email || "",
        password: "",
        role: res?.role || "",
        rolesLookupId: res?.rolesLookupId || "",
        firstName: res?.firstName || "",
        lastName: res?.lastName || "",
        middleName: res?.middleName || "",
        isActive: res?.isActive || null,
        isVatVerified: res?.isVatVerified ? true : false,
        dob: new Date(res?.dob || null),
        address: {
          street: res?.addresses?.street || "",
          city: res?.addresses?.city || "",
          state: res?.addresses?.state || "",
          postalCode: res?.addresses?.postalCode || "",
          country: res?.addresses?.country || "",
        },
        phoneNumber: res?.phoneNumber || "",
        salutation: res?.salutation || "",
        gender: res?.gender || "",
        TaskUser: tasksToSetState || [],
        SharedMails: res?.MailBoxPermissions,
        permissions: {
          lookupId: res?.permissions?.[0]?.lookupId,
          isPermission: res?.permissions?.[0]?.isPermission,
        },
        ConsultantName: introducerToSetState || [],
        profilePhotoUrl:
          res?.profilePhotoUrl && res?.type === "Internal"
            ? res?.profilePhotoUrl
            : res?.profilePhotoUrl
            ? `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${res?.profilePhotoUrl}`
            : null,
        type: res?.type,
        vatNumber: res?.vatNumber || null,
        xeroTrackingOptions: res?.xeroTrackingOptions || null,
        xeroSuspenseAccountInfo: res?.xeroSuspenseAccountInfo || null,
        isCommissionApplicable: res?.isCommissionApplicable,
        isCampaignHead: res?.isCampaignHead,
        campaignSourceType: res?.campaignSourceType?.name || "NA",
        isLogin: res?.isLogin,
        campaignLookupId: res?.campaignLookupId,
        campaignHeadVatNumber: res?.campaignHeadVatNumber,
      });
      //CALLING FUNCTION TO HANDLE DROPDOWN
      getTasksData(res?.TaskUser || []);
      setLoader(false);
    } else {
      setUserData({});
      setLoader(false);
    }
  };

  /* Method to deactivate user --> Open confirmation dialog */
  const handleClickDeactivateUser = () => {
    setDeactivateDialog(true);
  };

  /* Success Method to Deactivate user*/
  const handleSuccessDeactivateUser = async () => {
    try {
      const payload = {
        id: Number(params?.id),
        firstName: userData.firstName,
        isActive: userData?.isActive === true ? false : true,
        rolesLookupId: userData?.rolesLookupId || "",
        isCampaignHead: false,
      };
      setLoader(true);
      const response = await dispatch(deactivateUser(payload)).unwrap();
      if (response?.status === 200) {
        setLoader(false);
        setDeactivateDialog(false);
        if (
          userData?.isActive
            ? dispatch(
                showSnackbar({
                  show: true,
                  message: NOTIFICATION_MESSAGE?.USER_DEACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
            : dispatch(
                showSnackbar({
                  show: true,
                  message: NOTIFICATION_MESSAGE?.USER_ACTIVATE_SUCCESS,
                  severity: "success",
                })
              )
        )
          navigate("/app/manage/users/list");
      }
    } catch (error) {
      setLoader(false);
      setDeactivateDialog(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
      navigate("/app/manage/users/list");
    }
  };

  /* Method to get Tasks */
  const getTasksData = async (TaskUser) => {
    try {
      const response = await dispatch(
        getAllTasks({ query: { actor: "officeAdmin" } })
      ).unwrap();
      const filtered = response.filter((task) => {
        return !TaskUser.some((preTask) => task.id === preTask.taskId);
      });
      if (response?.length > 0) {
        setTasks(params.id ? filtered : response);
      } else {
        setTasks([]);
      }
    } catch (error) {}
  };

  /* Method to get Introducers */
  const getIntroducersData = async () => {
    try {
      const response = dispatch(getIntroducer());
    } catch (error) {}
  };

  /* Method to get Consultant */
  const getConsultantData = async () => {
    try {
      const response = dispatch(getConsultant());
    } catch (error) {}
  };

  /* Method to handle image */
  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedUserProfile(selectedFile);
    if (selectedFile) {
      const blobUrl = URL.createObjectURL(selectedFile);
      setUserData({
        ...userData,
        profilePhotoUrl: blobUrl,
      });
    }
  };

  /* Success Method for role change --> clear out taskUser, Consultants, roles  */
  const handleSuccessRoleChange = () => {
    if (tempRoleState === "OfficeAdmin") {
      setUserData({
        ...userData,
        TaskUser: [],
        ConsultantName: [],
        role: tempRoleState,
        phoneNumber: OFFICE_ADMIN_PHONE_NUMBER,
        isCommissionApplicable: false,
        isCampaignHead: false,
        campaignSourceType: null,
        isLogin: true,
      });
      setIsChangeRole(false);
    } else {
      setUserData({
        ...userData,
        TaskUser: [],
        ConsultantName: [],
        role: tempRoleState,
        phoneNumber: "",
      });
    }
    setIsChangeRole(false);
  };

  /* Close method to close confirmation dialog */
  const handleCloseRole = () => {
    setTempRoleState("");
    handleChangeFormFields(userData?.role, "role");
    setIsChangeRole(false);
  };

  /* Method to get Xero option set */
  const getXeroOptionSetData = async () => {
    try {
      setXeroLoader(true);
      const response = await dispatch(xeroOptionSet()).unwrap();
      if (response?.length > 0) {
        setXeroOption(response);
        setXeroLoader(false);
      } else {
        setXeroOption([]);
        setXeroLoader(false);
      }
    } catch (error) {
      setXeroOption([]);
      setXeroLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  /* Method to get Xero suspense user data */
  const getXeroSuspenseData = async () => {
    try {
      SetXeroSupenseLoader(true);
      const response = await dispatch(xeroSuspenseData()).unwrap();
      if (response?.Accounts?.length > 0) {
        SetXeroSuspenseUser(response?.Accounts);
        SetXeroSupenseLoader(false);
      } else {
        SetXeroSuspenseUser([]);
        SetXeroSupenseLoader(false);
      }
    } catch (error) {
      SetXeroSuspenseUser([]);
      SetXeroSupenseLoader(false);
      dispatch(
        showSnackbar({
          show: true,
          message: `${error?.message}`,
          severity: "error",
        })
      );
    }
  };

  // Method to get shared email
  const getSharedEmail = async () => {
    try {
      setLoader(true);
      const response = await dispatch(getSharedEmailIdData()).unwrap();
      if (response?.length > 0) {
        setEmailData(response);
        setLoader(false);
      } else {
        setEmailData([]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  // Method to change checkbox for is user access
  const handleChangeIsUserAccess = (checked) => {
    setUserData({
      ...userData,
      permissions: {
        lookupId: lookups?.UserAccess?.[0]?.id,
        isPermission: checked,
      },
    });
  };

  // UseEffect to clear localstorage user_id_path
  useEffect(() => {
    return () => {
      localStorage.removeItem("user_id_path");
    };
  }, []);

  useEffect(() => {
    getUserById();
    getIntroducersData();
    getConsultantData();
    getSharedEmail();
  }, []);

  return (
    <>
      {/* Loader Component */}
      {isLoader && <Loader />}

      {/* Confirmation dialog for deactivate user */}
      {isDeactivateDialog && (
        <AlertComponent
          open={isDeactivateDialog}
          handleSuccess={handleSuccessDeactivateUser}
          handleClose={() => setDeactivateDialog(false)}
          message={`Are you sure, want to ${
            userData?.isActive === true ? "Deactivate" : "Activate"
          } user?`}
        />
      )}

      {/* Confirmation dialog for update user */}
      {isUpdateUserDialog && (
        <AlertComponent
          open={isUpdateUserDialog}
          handleSuccess={handleClickUpdateUser}
          handleClose={() => setUpdateUserDialog(false)}
          message={"Are you sure, want to update user?"}
        />
      )}

      {/* Confirmation dialog for role change */}
      {isChangeRole && (
        <AlertComponent
          open={isChangeRole}
          handleSuccess={handleSuccessRoleChange}
          handleClose={handleCloseRole}
          message={"Are You Sure Want To Change Role."}
        />
      )}
      <Grid
        item
        xs={12}
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography className="serif_display_regular_26 dark_grey">
          <img
            src={BackButton}
            onClick={() => navigate("/app/manage/users/list")}
            style={{ cursor: "pointer", marginRight: "10px" }}
            alt="squiggle"
          />
          Manage User
        </Typography>
      </Grid>
      {/* Update User Form Layout */}
      <Grid
        className="create-user instruction_dashboard_scrollbar"
        style={{
          padding: "1rem",
          height: "90vh",
          overflow: "auto",
        }}
        container
      >
        {/* USER IMAGE */}
        <Grid item xs={2}>
          <Avatar
            className="updateUserAvatarBackground"
            onClick={
              userData?.type === "Internal"
                ? null
                : () => inputRef.current.click()
            }
          >
            <img
              // src={userData?.profilePhotoUrl || DefaultImage}
              src={
                userData?.profilePhotoUrl &&
                userData?.profilePhotoUrl?.startsWith("https")
                  ? userData?.profilePhotoUrl
                  : userData?.profilePhotoUrl === null
                  ? DefaultImage
                  : `${process.env.REACT_APP_API_PATH}${IMG_BASE_URL}${userData?.profilePhotoUrl}`
              }
              className="updateUserProfileImage"
              alt="Squiggle"
            />

            <input
              type="file"
              ref={inputRef}
              src={userImage}
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
          </Avatar>
          <Accordion style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              XERO Mapping
            </AccordionSummary>
            <AccordionDetails>
              {/* Xero Selected Option Name */}
              <Grid item xs={12}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Xero tracking category"
                  placeholder="Xero tracking category"
                  fullWidth
                  size="small"
                  disabled
                  value={userData?.xeroTrackingOptions?.Name || "NA"}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>
              {/* Xero Option Set / Tracking Options */}
              <Grid item xs={12} mt={2} mb={2}>
                <Autocomplete
                  fullWidth
                  onOpen={() =>
                    xeroOption?.length > 0 ? null : getXeroOptionSetData()
                  }
                  options={xeroOption || []}
                  getOptionLabel={(option) => option?.Name}
                  onChange={(event, value) =>
                    handleChangeFormFields(value, "xeroTrackingOptions")
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="normal_normal_600_12_Manropee"
                      size="small"
                      label="Xero tracking category"
                      placeholder="Select Xero tracking category"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {xeroLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Xero Selected Option Name for suspense Account */}
              <Grid item xs={12}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Xero Suspense"
                  placeholder="Xero Suspense"
                  fullWidth
                  size="small"
                  disabled
                  value={userData?.xeroSuspenseAccountInfo?.Name || "NA"}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>
              {/* Xero Suspense Account users */}
              <Grid item xs={12} mt={3}>
                <Autocomplete
                  fullWidth
                  onOpen={() =>
                    xeroSuspenseUser?.length > 0 ? null : getXeroSuspenseData()
                  }
                  options={xeroSuspenseUser || []}
                  getOptionLabel={(option) => option?.Name}
                  onChange={(event, value) =>
                    handleChangeFormFields(value, "xeroSuspenseAccountInfo")
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="normal_normal_600_12_Manropee"
                      size="small"
                      label="Xero Suspense"
                      placeholder="Select Xero Suspense"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {xeroSupenseLoader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* FORM INPUTS */}
        <Grid item xs={10} px={5}>
          <Grid container spacing={3}>
            {/* Salutation */}
            <Grid item xs={2}>
              <FormControl
                fullWidth
                required
                error={isError && !userData?.salutation}
              >
                <InputLabel
                  shrink={true}
                  className="normal_normal_600_12_Manropee"
                  style={{
                    lineHeight: "2.0375",
                    marginLeft: "-12px",
                    top: "3px",
                  }}
                >
                  Salutation
                </InputLabel>
                <Select
                  className="formControlField"
                  value={userData?.salutation}
                  label="Salutation"
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "salutation")
                  }
                  variant="standard"
                  size="small"
                >
                  {salutation?.length > 0 &&
                    salutation?.map((d) => (
                      <MenuItem key={d?.id} value={d?.name}>
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !userData?.salutation && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* First Name */}
            <Grid item xs={2.5}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="First Name"
                placeholder="First Name"
                fullWidth
                size="small"
                required
                disabled={userData?.type === "Internal"}
                value={userData?.firstName}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "firstName")
                }
                error={isError && !userData?.firstName}
                helperText={
                  isError && !userData?.firstName && VALIDATION_MSG.REQUIRED
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Middle Name */}
            <Grid item xs={2.5}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Middle Name"
                placeholder="Middle Name"
                fullWidth
                size="small"
                disabled={userData?.type === "Internal"}
                value={userData?.middleName}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "middleName")
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={2.5}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Last Name"
                placeholder="Last Name"
                fullWidth
                size="small"
                required
                disabled={userData?.type === "Internal"}
                value={userData?.lastName}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "lastName")
                }
                error={isError && !userData?.lastName}
                helperText={
                  isError && !userData?.lastName && VALIDATION_MSG.REQUIRED
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* Gender */}
            <Grid item xs={3}>
              <FormControl>
                <FormLabel
                  className="normal_normal_600_12_Manrope"
                  style={{ color: "#00CE3F" }}
                >
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Male"
                  name="radio-buttons-group"
                  onChange={(e) =>
                    handleChangeFormFields(e?.target?.value, "gender")
                  }
                  value={userData?.gender}
                >
                  {gender?.length > 0 &&
                    gender?.map((d) => (
                      <FormControlLabel
                        value={d?.name}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#00CE3F",
                              },
                              marginTop: -1,
                            }}
                          />
                        }
                        label={d?.name}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Job Title / Role */}
            <Grid item xs={3}>
              <FormControl
                fullWidth
                required
                error={isError && !userData?.role}
              >
                <InputLabel
                  className="normal_normal_600_12_Manropee"
                  shrink={true}
                  style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                >
                  Job Title
                </InputLabel>
                <Select
                  className="formControlField"
                  value={userData?.role}
                  label="Role"
                  onChange={(e) => handleRoleChange(e)}
                  variant="standard"
                  size="small"
                >
                  {roles?.length > 0 &&
                    roles?.map((d) => (
                      <MenuItem key={d?.name} value={d?.value}>
                        {d?.name}
                      </MenuItem>
                    ))}
                </Select>
                {isError && !userData?.role && (
                  <FormHelperText className="red">
                    {VALIDATION_MSG.REQUIRED}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Is User Access Checkbox */}
            {userData?.role === USER_ROLE?.OFFICE_ADMIN && (
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#00CE3F",
                        "&.Mui-checked": {
                          color: "#00CE3F",
                        },
                      }}
                      checked={userData?.permissions?.isPermission}
                      onChange={(e) =>
                        handleChangeIsUserAccess(e.target.checked)
                      }
                    />
                  }
                  label={UserAccessLabel}
                />
              </Grid>
            )}

            {/* DOB */}
            <Grid item xs={3}>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format={DATE_FORMAT}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        InputLabelProps: { shrink: true },
                        size: "small",
                        helperText: dateError
                          ? VALIDATION_MSG?.INVALID_DATE
                          : "",
                      },
                    }}
                    value={userData?.dob}
                    label="Date of Birth"
                    onChange={(e) => handleChangeFormFields(e, "dob")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onError={(newError) => setDateError(newError)}
                    className="normal_normal_600_12_Manropee outlined_border"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            {/* Mobile Number */}
            <Grid item xs={3}>
              <label
                style={{ position: "relative", bottom: 5 }}
                className="normal_normal_600_10_Manropee"
              >
                Work Number <span>*</span>
              </label>
              <PhoneInput
                country="UK"
                defaultCountry="GB"
                placeholder="Work Number"
                className="inputTextField border_color normal_normal_600_12_Manropee"
                value={userData?.phoneNumber}
                onChange={(e) => handleChangeFormFields(e, "phoneNumber")}
              />
              {isError && !userData?.phoneNumber && (
                <FormHelperText className="red">
                  {VALIDATION_MSG.REQUIRED}
                </FormHelperText>
              )}
              {/* <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                type="tel"
                label="Mobile Number"
                placeholder="Mobile Number"
                fullWidth
                size="small"
                required
                value={userData?.phoneNumber}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "phoneNumber")
                }
                error={
                  (isError && !userData?.phoneNumber) ||
                  (isError && !phoneNumberValidation(userData?.phoneNumber))
                }
                helperText={
                  (isError &&
                    !userData?.phoneNumber &&
                    VALIDATION_MSG.REQUIRED) ||
                  (isError &&
                    !phoneNumberValidation(userData?.phoneNumber) &&
                    VALIDATION_MSG.VALID_MOBILE_NUMBER)
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              /> */}
            </Grid>

            {/* Email */}
            <Grid item xs={4}>
              <TextField
                className="inputTextField normal_normal_600_12_Manropee"
                label="Email"
                type="email"
                placeholder="Email"
                fullWidth
                required
                size="small"
                value={userData?.email}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e) =>
                  handleChangeFormFields(e?.target?.value, "email")
                }
                error={
                  (isError && !userData?.email) ||
                  (isError && !emailValidation(userData?.email))
                }
                helperText={
                  (isError && !userData?.email && VALIDATION_MSG?.REQUIRED) ||
                  (isError &&
                    !emailValidation(userData?.email) &&
                    VALIDATION_MSG.VALID_EMAIL_ID)
                }
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            {/* If Role is not equal to INTRODUCER then so VAT Verified section */}
            {userData?.role !== USER_ROLE.INTRODUCER && (
              <>
                {/* VAT Verified */}
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel
                      className="normal_normal_600_12_Manropee"
                      shrink={true}
                      style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                    >
                      VAT Verified
                    </InputLabel>
                    <Select
                      className="formControlField"
                      value={userData?.isVatVerified}
                      label="VAT Verified"
                      onChange={(e) =>
                        handleChangeFormFields(
                          e?.target?.value,
                          "isVatVerified"
                        )
                      }
                      variant="standard"
                      size="small"
                    >
                      {IS_VAT_VERIFIED_DATA?.length > 0 &&
                        IS_VAT_VERIFIED_DATA?.map((d) => (
                          <MenuItem key={d?.id} value={d?.value}>
                            {d?.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {userData?.isVatVerified && (
                  <>
                    {/*  Vat Number  */}
                    <Grid ml={2} mt={2}>
                      <TextField
                        required
                        className="inputTextField normal_normal_600_12_Manropee"
                        label="VAT Number"
                        placeholder="VAT Number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {VAT_NUMBER_START}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleChangeFormFields(e?.target?.value, "vatNumber")
                        }
                        sx={{ m: 1, width: "25ch" }}
                        variant="standard"
                        value={userData?.vatNumber}
                        error={
                          (isError &&
                            userData?.isVatVerified &&
                            !userData?.vatNumber) ||
                          (isError &&
                            userData?.vatNumber &&
                            !vatNumberValidation(userData?.vatNumber))
                        }
                        helperText={
                          (isError &&
                            userData?.isVatVerified &&
                            !userData?.vatNumber &&
                            VALIDATION_MSG?.REQUIRED) ||
                          (isError &&
                            userData?.vatNumber &&
                            !vatNumberValidation(userData?.vatNumber) &&
                            VALIDATION_MSG.NUM_ONLY)
                        }
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid container xs={12} ml={3} mt={2} gap={3}>
              {/* Post Code */}
              <Grid item xs={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Post Code"
                  placeholder="Post Code"
                  fullWidth
                  size="small"
                  value={userData?.address?.postalCode}
                  onChange={(e) =>
                    handleChangeAddressFields(e?.target?.value, "postalCode")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Street */}
              <Grid item xs={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="Street"
                  placeholder="Street"
                  fullWidth
                  size="small"
                  value={userData?.address?.street}
                  onChange={(e) =>
                    handleChangeAddressFields(e?.target?.value, "street")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* City */}
              <Grid item xs={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="City"
                  placeholder="City"
                  fullWidth
                  size="small"
                  value={userData?.address?.city}
                  onChange={(e) =>
                    handleChangeAddressFields(e?.target?.value, "city")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* State */}
              <Grid item xs={2}>
                <TextField
                  className="inputTextField normal_normal_600_12_Manropee"
                  label="County"
                  placeholder="County"
                  fullWidth
                  size="small"
                  value={userData?.address?.state}
                  onChange={(e) =>
                    handleChangeAddressFields(e?.target?.value, "state")
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>

              {/* Country */}
              <Grid item xs={2}>
                <FormControl
                  fullWidth
                  required
                  error={isError && !userData?.address?.country}
                >
                  <InputLabel
                    className="normal_normal_600_12_Manropee"
                    shrink={true}
                    style={{ lineHeight: "2.0375", marginLeft: "-12px" }}
                  >
                    Country
                  </InputLabel>
                  <Select
                    className="formControlField"
                    value={userData?.address?.country}
                    label="Country"
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "country")
                    }
                    variant="standard"
                    size="small"
                  >
                    {country?.length > 0 &&
                      country?.map((d) => (
                        <MenuItem key={d?.id} value={d?.name}>
                          {d?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {isError && !userData?.address?.country && (
                    <FormHelperText className="red">
                      {VALIDATION_MSG.REQUIRED}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {userData.role !== USER_ROLE?.INTRODUCER && (
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <FormLabel
                      className="normal_normal_600_12_Manrope"
                      style={{ color: "#00CE3F" }}
                    >
                      Shared Emails
                    </FormLabel>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Update Shared Email Section */}
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        value={userData?.SharedMails || []}
                        options={emailData}
                        getOptionLabel={(option) =>
                          `${option?.emailName || option?.email}`
                        }
                        onChange={(event, value) =>
                          handleChangeSharedEmailId(value)
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="normal_normal_600_12_Manropee"
                            variant="outlined"
                            size="small"
                            // label="Shared Emails"
                            placeholder="Enter email ID"
                          />
                        )}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

            {/*  TASKS SPECIALIZATION  */}
            {/* Only when office admin is selected */}

            {userData.role === USER_ROLE?.OFFICE_ADMIN && (
              <Grid item xs={12}>
                <Autocomplete
                  // multiple
                  value={selectedTask || null}
                  options={tasks || []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, value) => handleChangeTasks(value)}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="normal_normal_600_12_Manropee"
                      size="small"
                      label="Tasks"
                      placeholder="Select Task"
                    />
                  )}
                />
              </Grid>
            )}

            {/* Section to show tasks only for office admin role */}
            {userData.role === USER_ROLE?.OFFICE_ADMIN && (
              <>
                {userData?.TaskUser?.length > 0 ? (
                  <>
                    <Grid item xs={12} mb={3}>
                      <Grid
                        style={{
                          border: "1px solid #dcdce1",
                          borderRadius: "6px",
                          padding: "3px",
                        }}
                      >
                        {userData?.TaskUser?.map((d, ind) => (
                          <Chip
                            style={{ margin: 6 }}
                            key="ind"
                            label={d?.name || "NA"}
                            onDelete={() => handleDeleteTask(d)}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography>No tasks added.</Typography>
                  </Grid>
                )}
              </>
            )}

            {/* only when Consultant role is selected Introducer */}
            {/* {userData.role === "Consultant" && (
                    <Grid item xs={6}>
                      <Autocomplete
                        multiple
                        value={userData?.IntroducersUser || []}
                        options={introducers}
                        getOptionLabel={(option) => `${option?.firstName}`}
                        onChange={(event, value) =>
                          handleChangeIntroducers(value)
                        }
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="inputTextField"
                            variant="standard"
                            size="small"
                            label="Introducers"
                            placeholder="Select Introducers"
                          />
                        )}
                      />
                    </Grid>
                  )} */}

            {/*  CONSULTANTS  */}
            {/*  only when Introducer is selected  */}

            {userData.role === USER_ROLE?.INTRODUCER && (
              <>
                <Grid item xs={2}>
                  <FormControl>
                    <FormLabel
                      className="normal_normal_600_12_Manrope"
                      style={{ color: "#00CE3F" }}
                    >
                      Is Commission Applicable?
                    </FormLabel>
                    <RadioGroup
                      fullWidth
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e) =>
                        handleChangeFormFields(
                          e?.target?.value,
                          "isCommissionApplicable"
                        )
                      }
                      value={userData?.isCommissionApplicable}
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#00CE3F",
                              },
                              marginTop: -1,
                            }}
                          />
                        }
                        label="Paid"
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#00CE3F",
                              },
                              marginTop: -1,
                            }}
                          />
                        }
                        label="Unpaid"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                  <FormControlLabel
                    // disabled
                    control={
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={userData?.isCampaignHead || false}
                        onChange={(e) =>
                          handleChangeFormFields(
                            e?.target?.checked,
                            "isCampaignHead"
                          )
                        }
                      />
                    }
                    label="Is Campaign Head?"
                  />

                  {/* Is Introducer login section */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#00CE3F",
                          "&.Mui-checked": {
                            color: "#00CE3F",
                          },
                        }}
                        checked={userData?.isLogin}
                        onChange={(e) =>
                          handleChangeFormFields(e?.target?.checked, "isLogin")
                        }
                      />
                    }
                    label="Can Introducer Login?"
                  />
                </Grid>
                <Grid item xs={3}>
                  <>
                    {/* VAT Verified */}
                    <FormControl fullWidth>
                      <InputLabel
                        className="normal_normal_600_12_Manropee"
                        shrink={true}
                        style={{
                          lineHeight: "2.0375",
                          marginLeft: "-12px",
                        }}
                      >
                        VAT Verified
                      </InputLabel>
                      <Select
                        disabled={
                          userData?.isCommissionApplicable === "false" ||
                          userData?.isCommissionApplicable === false
                        }
                        className="formControlField"
                        value={userData?.isVatVerified}
                        label="VAT Verified"
                        onChange={(e) =>
                          handleChangeFormFields(
                            e?.target?.value,
                            "isVatVerified"
                          )
                        }
                        variant="standard"
                        size="small"
                      >
                        {IS_VAT_VERIFIED_DATA?.length > 0 &&
                          IS_VAT_VERIFIED_DATA?.map((d) => (
                            <MenuItem key={d?.id} value={d?.value}>
                              {d?.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {userData?.isVatVerified && (
                      <>
                        {/*  Vat Number  */}
                        <TextField
                          style={{ marginTop: "1rem" }}
                          fullWidth
                          required
                          className="inputTextField normal_normal_600_12_Manropee"
                          label="VAT Number"
                          placeholder="VAT Number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {VAT_NUMBER_START}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            handleChangeFormFields(
                              e?.target?.value,
                              "vatNumber"
                            )
                          }
                          variant="standard"
                          value={userData?.vatNumber}
                          error={
                            (isError &&
                              userData?.isVatVerified &&
                              !userData?.vatNumber) ||
                            (isError &&
                              userData?.vatNumber &&
                              !vatNumberValidation(userData?.vatNumber))
                          }
                          helperText={
                            (isError &&
                              userData?.isVatVerified &&
                              !userData?.vatNumber &&
                              VALIDATION_MSG?.REQUIRED) ||
                            (isError &&
                              userData?.vatNumber &&
                              !vatNumberValidation(userData?.vatNumber) &&
                              VALIDATION_MSG.NUM_ONLY)
                          }
                        />
                      </>
                    )}
                  </>
                </Grid>
                {/* VAT DETAILS OF CAMPAIGN HEAD  */}
                <Grid item xs={3}>
                  {userData?.campaignHeadVatNumber && (
                    <>
                      {/*  Vat Number of campagion head  */}
                      <TextField
                        fullWidth
                        className="inputTextField normal_normal_600_12_Manropee"
                        label="Campaign Head VAT Number"
                        placeholder="campaign head VAT number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {VAT_NUMBER_START}
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        value={userData?.campaignHeadVatNumber}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled
                    className="inputTextField normal_normal_600_12_Manropee"
                    label="Campaign"
                    placeholder="Campaign"
                    fullWidth
                    size="small"
                    value={userData?.campaignSourceType || null}
                    onChange={(e) =>
                      handleChangeAddressFields(e?.target?.value, "state")
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    // multiple
                    value={selectedConsultant || null}
                    options={allConsultantArray?.concat(consultants)}
                    getOptionLabel={(option) =>
                      `${option?.firstName} ${option?.lastName}`
                    }
                    onChange={(event, value) => handleChangeConsultants(value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="normal_normal_600_12_Manropee"
                        variant="outlined"
                        size="small"
                        label="Consultants"
                        placeholder="Select Consultants"
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {userData.role === USER_ROLE?.INTRODUCER && (
              <>
                {userData?.ConsultantName?.length > 0 ? (
                  <>
                    <Grid item xs={12} mb={4}>
                      <Grid
                        style={{
                          border: "1px solid #dcdce1",
                          borderRadius: "6px",
                          padding: "3px",
                        }}
                      >
                        {userData?.ConsultantName?.map((d, ind) => (
                          <Chip
                            style={{ margin: 6 }}
                            key="ind"
                            label={`${d?.firstName} ${d?.lastName}` || "NA"}
                            onDelete={() => handleDeleteConsultant(d)}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography>No consultant added.</Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* User Activate / Deactivate Button */}
      <Grid container spacing={2} className="Save_Button_Container">
        <Grid
          item
          xs={6}
          sx={{ display: "flex", justifyContent: "space-evenly" }}
          m={1}
        >
          <Grid>
            <Button
              onClick={handleClickDeactivateUser}
              className="deactivate_button"
              variant="contained"
              size="small"
            >
              <Typography
                style={{ textTransform: "capitalize" }}
                className="normal_normal_16_Manrope"
              >
                {userData?.isActive === true ? "Deactivate" : "Activate"}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {/* Save Button */}
        <Grid
          item
          xs={5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          m={1}
        >
          <Button
            disabled={!userData.isActive}
            className="save_button"
            onClick={handleClickOpenConfirmUpdateUserDialog}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateUser;
